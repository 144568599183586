.promo {
    &__content {
        & h4 {
            font-weight: 600;
            font-size: 48px;
            line-height: 59px;
            margin-bottom: 60px;
            color: #FFFFFF;
        }
    }
}