.logo {

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        transition: unset;
        img {
            height: 60px;
            object-fit: contain;

        }

    }
}
