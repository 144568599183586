.footer-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 60px 0 20px 0;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url('../img/backgrounds/lime-gray.svg');
    background-repeat: no-repeat;
    background-position: left 100px bottom;
    &--top {
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        grid-gap: 40px;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 32px;
    }

}
.footer {
    color: #FFFFFF;
    &-row {
        &:last-child {
            margin-left: auto;
        }
        & .copyright {
            display: none;
        }
    }
    &__title {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 8px;
    }
    &__subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
    }
    &-copyright {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        & .logo {
            margin-bottom: 32px;
            & a img {
                height: 30px;
            }
        }

    }
    & .copyright {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
    }
}




