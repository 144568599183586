.rooms {
    &-header {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 500px;
        align-content: center;
        background-repeat: no-repeat;
        background-position: center right;
        background-size: contain;
        overflow: hidden;
        &.eco {
            background-image: url(../img/backgrounds/lime-green.svg);
        }
        &.regular {
            background-image: url(../img/backgrounds/lime-yellow.svg);
        }
        &__img {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            background-position: center;
            //&.eco {
            //    background-image: url(../img/section/split01.jpg);
            //}
            //&.regular {
            //    background-image: url(../img/section/split02.jpg);
            //}

        }
        &__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 20px;
            width: 100%;
            & h1 {
                font-weight: 600;
                font-size: 32px;
                line-height: 36px;
                color: #444444;
            }
            & p {
                font-size: 20px;
                line-height: 1.2;
                display: flex;
                align-items: center;
                color: #444444;
                margin-bottom: 0;
            }
        }
        &__description {
            max-width: 580px;
        }
    }
    &-logo {
        position: absolute;
        height: 120px;
    }
    &-header-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &-type {
        display: flex;
        align-items: center;
        justify-content: center;
        & i {
            margin-right: 12px;
            font-size: 28px;
        }
        &.eco {
            & i {
                color: #70E8A0;
            }
        }
        &.regular {
            & i {
                color: #FFDE57;
            }
        }
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
    }
    &-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 24px;
        background: #FFFFFF;
        box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.1);
        border-radius: 10px;

        &__preview {
            position: relative;
            border-radius: 10px;
            margin-bottom: 30px;
            aspect-ratio: 3/2;
            overflow: hidden;
            background-size: contain;
            background: #F5F5F5 url('../img/backgrounds/lime.svg') no-repeat center;
            background-size: 200px;

            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__price {
            position: absolute;
            left: 24px;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 125px;
            height: 50px;
            background: rgba(112, 232, 160, 0.8);
            border-radius: 10px 10px 0 0;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #FFFFFF;
        }
        &__availability {
            position: absolute;
            top: 7px;
            right: 7px;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 10px;
            font-size: 8px;
            line-height: 10px;
            color: #444444;
            & a {
                display: flex;
                flex-direction: column;
                padding: 6px;
            }
            & i {
                font-size: 24px;
                line-height: 1;
                margin-bottom: 4px;
                color: rgba(0, 0, 0, 0.5);
            }
        }
        &__title {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #484848;
            margin-bottom: 5px;
            text-align: left;
        }
        &__description {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #9A9A9A;
            margin-bottom: 16px;
            text-align: left;
            min-height: 68px;
            & p {
                margin-bottom: 0;
            }
        }
        &__highlights {
            display: grid;
            grid-template-columns: repeat(auto-fill,minmax(15%,1fr));
            grid-gap: 16px;
            width: 100%;
            font-size: 32px;
        }
        &__buttons {
            margin-top: 40px;
            & .button {
                width: 100%;
                min-width: unset;
                height: 60px;
            }
        }
    }
}
.room {
    &-wrapper {
        display: grid;
        grid-template-columns: 50% 1fr;
        grid-gap: 30px;
    }
    &__price {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 2;
        padding: 12px;
        background: rgba(112, 232, 160, 0.8);
        border-radius: 10px;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        & .price__volume {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            margin-right: 2px;
        }
    }
    &-amenities {
        padding: 30px;
        background: #F5F5F5;
        border-radius: 10px;
        height: 100%;
        color: #484848;
        &__title {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 30px;
        }
        &__list {
            display: flex;
            flex-direction: column;
            gap: 20px;
            & li {
                display: flex;
                align-items: center;
                & i {
                    width: 30px;
                    height: 30px;
                    font-size: 30px;
                    margin-right: 20px;
                }
                & span {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
    &-rules {
        padding: 30px;
        background: #FFFFFF;
        box-shadow: 0 12px 36px rgba(0, 0, 0, 0.11);
        border-radius: 15px;
        &__title {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 20px;
        }
        &__time {
            display: flex;
            margin-bottom: 32px;
            & .check {
                display: flex;
                flex-direction: column;
                &:first-child {
                    margin-right: 60px;
                }
                &__title {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    color: #6D6D6D;
                    margin-bottom: 8px;
                }
                &__time {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #70E8A0;
                }
            }
        }
        &__list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 20px;
            font-size: 14px;
            line-height: 17px;
            color: #6D6D6D;
            & li {
                display: flex;
                align-items: center;
                &:before {
                    content: '';
                    display: block;
                    height: 6px;
                    width: 6px;
                    border-radius: 3px;
                    background: #70E8A0;
                    margin-right: 12px;
                }
            }
        }
    }
    &-info {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    &-booking {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        height: 100%;
        max-height: 500px;
        &__wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            gap: 30px;
        }
        & .form__section {
            width: 100%;
        }
    }
    &-safety {
        margin-top: auto;
        padding: 30px 0;
        &__title {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #484848;
            margin-bottom: 30px;
        }
        &__list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px 20px;
        }
        &__item {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #484848;
            & i {
                font-size: 32px;
                margin-right: 16px;
            }
        }
    }
}
