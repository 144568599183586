.form--booking {
    padding: 30px;
    background: #FDFDFD;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    & .form__input:not(:last-child) {
        margin-bottom: 30px;
    }
    & input {
        height: 50px;
        background: #F5F6F8;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        color: #7D7C7C;
    }
    & .form__input--date {
        max-width: 100%;
        & i {
            top: auto;
            bottom: 0;
        }
    }
    & .form__section--submit {
        margin-top: 60px;
    }
    & .room__total {
        font-weight: 400;
        font-size: 16px;
        color: #B0B0B0;
        margin-bottom: 10px;
        & span {
            font-weight: 600;
            color: #7D7C7C;
        }
    }
}