.burger-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    &__item {
        padding: 10px;
        width: 100%;
        text-align: center;
        &:not(:last-child) {
            border-bottom: 1px solid #EAEFF3;
        }
        &.eco {
            & i {
                color: #70E8A0;
            }
        }
        &.regular {
            & i {
                color: #FFDE57;
            }
        }
        &.coliving {
            & i {
                color: rgba(215, 64, 44, 0.64);
            }
        }
    }
    &__title {
        font-weight: 600;
        margin-bottom: 8px;
        color: #444;
        text-align: center;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        &.bold {
            color: #444;
            font-weight: 600;
        }
        & i {
            margin-right: 10px;
        }
    }
    &__footer {
        margin-top: auto;
        & .burger-menu__title {
            margin-bottom: 10px;
            font-size: 12px;
            text-transform: uppercase;
        }
        & .socials {
            margin-bottom: 42px;
            justify-content: center;
            & .socials__item {
                font-size: 24px;
            }
        }
        & .logo a img {
            height: 32px;
        }
    }
    &__wrapper {
        position: fixed;
        top: 0;
        visibility: hidden;
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        max-width: 480px;
        background: #F7FAFB;
        z-index: 11;
        transition: all 0.3s;
        padding: 24px 16px;
        overflow: auto;
        &.opened {
            visibility: visible;
            opacity: 1;

        }
    }
    &__opener {
        font-size: 32px;
        cursor: pointer;
    }
    &__closer {
        position: absolute;
        right: 32px;
        top: 32px;
        font-size: 32px;
        cursor: pointer;
        z-index: 12;
    }

}
.burger-submenu {
    margin-bottom: 30px;
}