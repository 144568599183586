.header-menu {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 24px;

    &__list {
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
    }
    &__item {
        position: relative;
        display: flex;
        align-items: center;
        white-space: nowrap;
        &.eco {
            & i {
                color: #70E8A0;
            }
        }
        &.regular {
            & i {
                color: #FFDE57;
            }
        }
        & i {
            font-size: 8px;
            margin-left: 12px;
        }
        &:not(:last-child) {
            margin-right: 32px;
        }

    }
    &__link {
        position: relative;
        display: flex;
        align-items: center;
        opacity: 1;
        transition: $t-color;
        &:hover {
            cursor: pointer;
        }
        &.opened {
            & i {
                transform: rotate(180deg);
            }
        }
    }
}
.header-submenu {
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    z-index: 9;
    background: #FFFFFF;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    display: none;
    & .header-menu {
        &__item {
            height: 80px;
            padding: 0 25px;
            &:not(:last-child) {
                margin-right: 0;
            }
            &.active {
                color: inherit;
                background: #EBFFEB;
            }
            &:hover {
                color: inherit;
                background: rgba(235, 255, 235, 0.5);
            }
        }
        &__link {
            display: flex;
            align-items: center;
            & i {
                font-size: 28px;
                margin-left: 0;
                margin-right: 12px;
            }
        }
    }
}