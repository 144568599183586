html {
	font-size: $font-size-default;
	font-family: $font-primary;
	font-weight: 400;
}
h1,
h2,
h3,
h4,
h5 {
	line-height: normal;
	user-select: none;
	margin-bottom: 24px;
	font-weight: 600;
	font-family: $font-primary;
}
h1 {
	display: block;
	width: 100%;
	font-size: 36px;
	line-height: 44px;
}
h2 {
	display: block;
	width: 100%;
	font-size: 40px;
	font-weight: 800;
	line-height: 1.1;
}
h3 {
	font-size: 24px;
	line-height: 1.2;
	font-weight: 600;
	margin-bottom: 16px;
}
h4 {
	font-size: 16px;
	font-weight: 600;
}
h5 {
	font-size: 16px;
	font-weight: 500;
}
h6 {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
}
p {
	margin-bottom: 16px;
	line-height: 1.5;
}
li,
a {
	user-select: none;
	transition: color .2s;
}
i {
	transition: color .2s;
}
b {
	font-weight: 600;
}
