.contacts {
    &__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;
        padding: 40px 0;
    }
    &__address {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        grid-gap: 40px;
        & li {
            margin-bottom: 5px;
            user-select: auto;
            & a {
                text-decoration: underline;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        & address {
            font-style: normal;
        }
    }

}