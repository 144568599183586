.booking {
    &-calendar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 40px;
        background: #FDFDFD;
        box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin-bottom: 40px;
        & {
            & .air-datepicker.-inline- {
                border: none;
                background: transparent;
                width: 100%;
            }
            & .air-datepicker-nav--title {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #000000;
                text-transform: unset;
            }
            & .air-datepicker-nav--title i {
                color: #000000;
            }
            & .air-datepicker-body--day-name {
                font-size: 12px;
                line-height: 18px;
                color: #000000;
                font-weight: 500;
            }
            & .air-datepicker-nav--action path {
                stroke: #70E8A0;
            }
            & .air-datepicker-nav {
                border-bottom: none;
            }
        }
        &__wrapper {
            display: flex;
            flex-direction: column;
        }
        &__title {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #7D7C7C;
            padding-bottom: 8px;
            margin-bottom: 8px;
            text-align: center;
            width: 100%;
            border-bottom: 1px solid #D9D9D9;
        }
    }
    &-legend {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #9A9A9A;
        padding: 0 16px;
        &__item {
            display: flex;
            align-items: center;
            &:not(:last-child) {
                margin-bottom: 12px;
            }
            & .booked, .avaliable, .yours {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border-radius: 4px;
                margin-right: 12px;
                color: #000000;
            }
            & .booked {
                background: #D9D9D9;
            }
            & .yours {
                background: #FFDE57;
            }
        }
    }
    &-steps {
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 120px;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            z-index: -1;
            transform: translateY(-50%);
            display: block;
            height: 1px;
            width: 300px;
            background: #E5E5E5;
        }
        & .step {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            background: #E5E5E5;
            font-size: 24px;
            color: #898989;

            &:not(:last-child) {
                margin-right: 80px;
            }
            &.current {
                font-weight: 500;
                background: rgba(112, 232, 160, 0.5);
                &:before {
                    content: '';
                    position: absolute;
                    z-index: -1;
                    display: block;
                    width: 78px;
                    height: 78px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    border: 1px solid #E5E5E5;
                }
            }
        }
    }
    &__wrapper {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 240px;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: 1px;
            height: 50%;
            background: #E5E5E5;
        }

    }
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        grid-gap: 40px;

    }
    &__img {
        width: 100%;
        max-height: 450px;
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 30px;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__total {
        padding: 30px;
        background: #FFFFFF;
        box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }
    &__type {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: rgba(17,34,17,.7);
        margin-bottom: 15px;
        & img {
            width: 26px;
            height: 26px;
            margin-right: 4px;
        }
    }
    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #112211;
        margin-bottom: 15px;
    }
    &__details {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #112211;
        & li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0;
            &:last-child {
                border-top: 1px solid #E5E5E5;
                padding-top: 30px;
                margin-top: 15px;
            }
        }
    }
    &__value {
        font-weight: 600;
    }
    &__dates {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        padding: 8px;
        background-color: rgba(112, 232, 160, 0.3);
        border-radius: 10px;
        margin-bottom: 15px;
        background-image: url('../img/elements/range.svg');
        background-repeat: no-repeat;
        background-position: center;
    }
    &__checkin, &__checkout {
        & .booking__date {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 8px;
        }
        & span {
            font-weight: 500;
            font-size: 14px;
        }
    }
    &__checkin {
        & .booking__date {
            color: #52503B;
        }
    }
    &__checkout {
        text-align: right;
    }
    &__form {
        & .booking__buttons {

            & .button {
                width: 100%;
            }
        }
    }
    &-payment {
        padding: 30px;
        background-color: #FFFFFF;
        box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background-image: url("../img/backgrounds/glass-black.svg");
        background-repeat: no-repeat;
        background-position: bottom 120px right;
        background-size: 200px;
        margin-bottom: 60px;
        &__description {
            & a {
                color: #0070E0;
                font-weight: 500;
            }
        }
        &__method {
            display: flex;
            align-items: center;
            margin: 50px 0;
        }
        & .button--t3 {
            color: #FFFFFF;
            letter-spacing: unset;
        }
        &__methods {
            background-color: #FFFFFF;
            box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            & .form__section {
                width: 100%;
                padding: 16px;
            }
            & .booking__buttons {
                padding: 30px;
                margin-top: 0;
                border-top: 1px solid #E5E5E5;
            }
            & .button--t3 {
                color: #FFFFFF;
                letter-spacing: unset;
            }

        }
    }
    &-notification {
        &__success {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 450px;
            background-color: #FFFFFF;
            box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            margin-bottom: 30px;
            background-image: url("../img/backgrounds/glass-black.svg");
            background-repeat: no-repeat;
            background-position: bottom 30px center;
            background-size: 200px;
            padding: 90px 30px;
            & i {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #70E8A0;
                font-size: 32px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                color: #FFFFFF;
                margin-bottom: 50px;
            }
            & h4 {
                font-weight: 600;
                font-size: 32px;
                margin-bottom: 0;
                text-align: center;
            }
        }
        &__description {
            color: #52503B;
        }
    }
}