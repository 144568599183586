.header {
    background-color: #FFFFFF;
    border-bottom: 1px solid #9A9A9A;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
    &__contacts {

        color: #9A9A9A;
        & .contacts__phone {
            display: flex;
            align-items: center;
            transition: $t-color;
            &:not(:last-child) {
                margin-bottom: 8px;
            }
            &:hover {
                color: #000000;
            }
            & i {
                font-size: 24px;
                margin-right: 24px;
            }
            &.eco {
                & i {
                    color: #70E8A0;
                }
            }
            &.regular {
                & i {
                    color: #FFDE57;
                }
            }
        }
    }

}
.header-layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px;
    align-items: center;
    height: 100px;
}
.header-row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 24px;
    & .button {
        width: fit-content;
        letter-spacing: unset;
        text-transform: uppercase;
    }
    &:first-child {
        justify-content: flex-start;
        align-items: center;
        //padding-bottom: 85px;
    }
    &:last-child {
        justify-content: flex-end;
        align-items: center;
        //padding-bottom: 85px;
    }
}
.lang {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
    &__item {
        transition: color .2s;
        &:not(:last-child) {
            border-right: 1px solid rgba(0, 0, 0, 0.5);
            padding-right: 8px;
            margin-right: 8px;
        }
        &:hover {
            color: #000000;
        }
    }
}