.search {
    position: relative;
    width: 290px;
    flex-shrink: 0;

    &__button {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        color: #A7BAC9;
    }
    & input {
        padding-left: 48px;
    }
}