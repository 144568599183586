.menu-mobile {
    position: fixed;
    bottom: 0;
    z-index: 10;
    display: none;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    color: #A7BAC9;
    padding: 8px;
    background: #F7FAFB;
    &__item {
        text-align: center;
        & span {
            font-size: 8px;
            line-height: 10px;
        }
        & i {
            font-size: 28px;
        }
        &.active {
            color: #009FF5;
        }
    }
    &__link {
        display: flex;
        flex-direction: column;
        &.active {
            color: #009FF5;
        }
    }
}
.profile-mobile {
    &__wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
        padding: 64px 16px 54px 16px;
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0 4px 40px rgb(55 73 87 / 12%);
        opacity: 0;
        visibility: hidden;
        overflow: auto;
        transition: opacity 0.2s;

        &.opened {
            opacity: 1;
            visibility: visible;
        }
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
}