.breadcrumbs {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #7D7C7C;
    margin-bottom: 30px;
    & a {
        &:hover {
            color: #434343;
        }
    }
    & li {
        &:not(:last-child) {
            &:after {
                content: '/';
            }
        }
        &:last-child {
            color: #434343;
        }
    }

}