.feedback {
    &-slider__wrapper {
        position: relative;
        width: 100%;
        max-width: 1920px;
        z-index: 1;
        padding: 0 100px;
        margin: 70px auto 20px auto;
        & .swiper, .swiper-container {
            overflow: unset;
        }
    }
    &-slider {
        &__item {
            padding: 32px 42px;
            background: #FFFFFF;
            color: #333333;
            border-radius: 4px;
            text-align: left;
            //transform: scale(.8);
            //&.swiper-slide-active, &.swiper-slide-next {
            //    transform: scale(1);
            //}
        }
        &__buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: 22px;
        }
        &-prev, &-next {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 25px;
            border: 1px solid #7D7C7C;
            &:hover {
                cursor: pointer;
            }
        }
    }
    &__rating {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 24px;
        color: #52503B;
        & i {
            margin-right: 2px;
        }
    }
    &__title {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 12px;
    }
    &__content {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 12px;
        min-height: 100px;
    }
    &__name {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        padding-left: 12px;
        border-left: 3px solid #70E8A0;
    }
}
.form--feedback {
    & .form__section--submit {
        display: grid;
        grid-template-columns: 1fr 150px;
        grid-gap: 16px;
    }
}