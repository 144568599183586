.slider-main {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px 120px;
    .swiper-container {
        width: calc(100% - 435px);
    }
    &__item {
        display: flex;
        justify-content: space-between;
        height: 360px;
        width: 80%;
    }
    &__content {
        width: 100%;
        height: 100%;
        background: radial-gradient(42.48% 63.78% at 50% 50%, #810384 33.33%, #170067 100%);
        padding: 52px 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        z-index: 1;
        text-align: left;
    }
    &__title {
        font-size: 64px;
        line-height: 1;
        font-weight: 800;
        text-transform: uppercase;
        span {
            color: $color-text-dark;
        }
    }
    &__description {
        p {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}