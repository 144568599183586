.swiper-half {
    &__title {
        font-weight: 500;
        font-size: 32px;
        line-height: 59px;
        margin-bottom: 0;
    }
    &__description {
        margin-top: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.2;
        text-align: left;
        & p {
            line-height: 1.2;
        }
    }
    &__buttons {
        display: flex;
        align-items: center;
        width: 100%;
        & .button {
            margin-right: 42px;
        }
    }
    &__navigation {
        position: absolute;
        top: 50%;
        z-index: 11;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &-prev, &-next {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background: #FFFFFF;
        border: 1px solid #FFFFFF;
        font-size: 12px;
        margin: 10px 0;
        &.swiper-button-disabled {
            transition: opacity .2s;
            opacity: 0;
        }
        &:hover {
            cursor: pointer;
        }
    }
    &.swiper-container {
        position: relative;
        overflow: hidden;
        height: 500px;
    }
    .swiper-slide {
        display: flex;
        overflow: hidden;
        width: 100%;

        &.swiper-slide-active {
            .swiper-half-l {
                opacity: 1;
                transform: translateY(0);
            }

            .swiper-half-r {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .swiper-half-l {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            height: 100%;
            opacity: 0;
            position: relative;
            transform: translateY(-100%);
            transition: 1s ease;
            width: 50%;
            color: #FFFFFF;
            padding: 60px;

            & img {
                width: 200px;
            }
        }

        .swiper-half-r {
            height: 100%;
            opacity: 0;
            position: relative;
            transition: 1s ease;
            transform: translateY(100%);
            width: 50%;
            & i {
                position: absolute;
                top: 32px;
                left: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 116px;
                height: 116px;
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.5);
                font-size: 64px;
                color: #FFFFFF;
            }
            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}
.swiper-pagination.long {
    position: unset;
    justify-content: center;
    display: flex;
    & .swiper-pagination-bullet {
        height: 10px;
        width: 100px;
        border: 2px solid #FFFFFF;
        border-radius: 10px;
        margin: 0 10px !important;

    }
    & .swiper-pagination-bullet-active {
        background-color: #FFFFFF;
    }
}
.swiper-pagination__wrapper {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
}