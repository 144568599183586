body {
	color: $color-text;
	transition: color 0.4s;
	background-color: $color-body;
	&.fixed {
		overflow: hidden;
		height: 100vh;
	}
	&.blur {
		.main {
			filter: blur(3px);
			transition: all 0.3s ease-out;
		}
	}
}
.helper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	margin-top: 100px;
	background-color: var(--background-body);

}
.main {
	position: relative;
	z-index: 1;
}

// -----------------------------------------------
// Container
// -----------------------------------------------
.container {
	margin: 0 auto;
	padding: 0 100px;
	max-width: 1920px;
	width: 100%;
}



// -----------------------------------------------
// Sections styles
// -----------------------------------------------
.section {
	text-align: center;


	&__title {
		font-weight: 600;
		font-size: 32px;
		line-height: 44px;
		margin-bottom: 20px;
		color: $color-text-dark;
	}
	&__subtitle {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: $color-text-dark;
	}
	&-main {
		position: relative;
		height: calc(100vh - 140px);
		overflow: hidden;
		&__title {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: #FFFFFF;
			& h2 {
				font-weight: 500;
			}
			& .lime {
				width: 200px;
			}

		}
		&__content {
			height: 100%;
		}
		&__image {
			width: 100%;
			height: 100%;
			& img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	}
	&-discount {
		background: #EBFFEB;
		padding: 6px 100px;
		& h4 {
			font-weight: 500;
			font-size: 20px;
			line-height: 28px;
			color: #7D7C7C;
			margin-bottom: 0;
		}
	}
	&-booking {
		padding-top: 60px;
		padding-bottom: 60px;
		background-color: #F5F5F5;
		background-image: url(../img/backgrounds/lighthouse.svg);
		background-repeat: no-repeat;
		background-position: bottom -100px right 100px;
		text-align: center;
	}
	&-select-rooms {
		padding: 60px 0;
		background-color: #F5F5F5;
		&.hide {
			display: none;
		}
	}
	&-rooms {
		padding: 60px 0;
	}
	&-promo {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		padding: 0 300px;
		height: 400px;
		background-image: url(../img/backgrounds/promo-surf.jpg);
	}
	&-services {
		padding: 60px 0;
		background-color: #F5F5F5;

	}
	&-activity {
		padding-top: 60px;
	}
	&-feedback {
		padding: 78px 0;
		background-color: #FFDE57;
		background-image: url(../img/backgrounds/lime-white.svg), url(../img/backgrounds/slice-white.svg);
		background-repeat: no-repeat;
		background-position: top 50px left, top 100px right 200px;
		text-align: center;
	}
	&-faq {
		position: relative;
		padding-top: 64px;
		padding-bottom: 40px;
		&:after {
			content: '';
			position: absolute;
			right: 180px;
			bottom: 0;
			width: 320px;
			height: 190px;
			background-image: url('../img/backgrounds/letter.svg');
			background-repeat: no-repeat;
		}


	}
	&-activities {
		padding: 60px 0;
	}
	&__buttons {
		margin: 20px auto;
	}




}

// -----------------------------------------------
// Tabs styles
// -----------------------------------------------
.tabs {
	&-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 24px;
	}
	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-text-custom;
		font-size: 14px;
		padding: 0 20px;
		text-transform: uppercase;
		font-weight: 700;
		height: 48px;
		cursor: pointer;
		transition: $t-color;
		&:hover {
			color: $color-text-dark;
		}
		&.active {
			color: $color-text-custom;
			cursor: default;
		}
	}
}
.tooltip {
	position: absolute;
	top: calc(-100% - 6px);
	left: 50%;
	transform: translateX(-50%);
	visibility: hidden;
	opacity: 0;
	padding: 8px 16px;
	background: #009FF5;
	border-radius: 8px;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #FFFFFF;
	white-space: nowrap;
	transition: opacity .4s;
	&:after {
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%) rotate(45deg);
		bottom: -5px;
		display: block;
		width: 10px;
		height: 10px;
		background-color: #009FF5;
	}
}
$lite:  #EFF3F6;
$brand: #009FF5;
$size: 48px;
//.loader {
//	width : $size;
//	height: $size;
//	border: 5px solid $lite;
//	border-bottom-color: $brand;
//	border-radius: 50%;
//	display: inline-block;
//	animation: rotation 1s linear infinite;
//	&__wrapper {
//		display: flex;
//		justify-content: center;
//		align-items: center;
//	}
//}
//@keyframes rotation {
//	0% { transform: rotate(0deg) }
//	100% { transform: rotate(360deg) }
//}