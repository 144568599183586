.slider-activity {
    margin-top: 30px;
    .swiper-slide {
        & .swiper-half__title {
            margin-bottom: 0;
        }
        & .swiper-half__description {
            margin: 0;
        }
        & .swiper-half-l {
           display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
        &.surfboard {
            background-color: rgba(70, 150, 155, 0.59);
            & .button--t2 {
                &:hover {
                    color: rgba(70, 150, 155, 0.59);
                }
            }
            .swiper-half-l {
                background-image: url(../img/backgrounds/waves.svg);
                background-repeat: no-repeat;
                background-position: top 200px right;
            }
        }
        &.yoga {
            background-color: #FBC028;
            & .button--t2 {
                &:hover {
                    color: #FFC960;
                }
            }
            //.swiper-half-l {
            //
            //    background-image: url(../img/backgrounds/lime-gray.svg);
            //    background-repeat: no-repeat;
            //    background-position: bottom right 50px;
            //}
        }
        &.hiking {
            background-color: rgba(103, 146, 49, 0.64);
            & .button--t2 {
                &:hover {
                    color: rgba(103, 146, 49, 0.64);
                }
            }
            //.swiper-half-l {
            //
            //    background-image: url(../img/backgrounds/route.svg);
            //    background-repeat: no-repeat;
            //    background-position: bottom 40px right;
            //    background-size: contain;
            //}
        }
        &.sup {
            background-color: rgba(103, 146, 49, 0.64);
            & .button--t2 {
                &:hover {
                    color: rgba(103, 146, 49, 0.64);
                }
            }
            //.swiper-half-l {
            //
            //    background-image: url(../img/backgrounds/lime-green.svg);
            //    background-repeat: no-repeat;
            //    background-position: bottom 40px right;
            //}
        }
        &.dinner {
            background-color: rgba(215, 64, 44, 0.64);
            & .button--t2 {
                &:hover {
                    color: rgba(215, 64, 44, 0.64);
                }
            }
            //.swiper-half-l {
            //    background-image: url(../img/backgrounds/castle.svg);
            //    background-repeat: no-repeat;
            //    background-position: bottom right 100px;
            //}
        }
    }
}
.activity {
    &-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        background: #F5F5F5;
        &__item {
            font-size: 20px;
            line-height: 24px;
            margin: 0 25px;
            &:hover, &.active {
                font-weight: 600;
            }
        }
    }
}