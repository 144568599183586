@media all and (max-width: 1440px) {
    .services-list {
        padding: 0;
    }
    .room-booking {
        display: flex;
        flex-direction: column-reverse;
        max-height: unset;
    }
    .contacts__wrapper {
        grid-template-columns: 1fr 2fr;
    }
}

@media all and (max-width: 1280px) {
    .logo a img {
        height: 80px;
    }
    .container {
        padding: 0 20px;
    }
    .header-layout {
        height: 120px;
    }
    .header-row:first-child, .header-row:last-child {
        align-items: center;
        padding: 0;
    }
    .section-discount h4 {
        font-size: 24px;
    }
    .services-list {
        grid-template-columns: repeat(4, 1fr);
    }
    .form--availability {
        grid-template-columns: 1fr;
        max-width: 420px;
    }
    .swiper-half .swiper-slide .swiper-half-l {
        padding: 20px 40px;
    }
    .swiper-pagination.long .swiper-pagination-bullet {
        width: 50px;
    }
    .faq__item {
        padding: 0 32px;
    }
    .rooms__list {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 35px;
    }
    .booking__wrapper {
        grid-gap: 40px;
    }
    .contacts__wrapper {
        display: flex;
        flex-direction: column;
    }
    .contacts__address {
        width: 100%;
        flex-direction: unset;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .long-stay-amenities__wrapper {
        grid-template-columns: 1fr;
    }
}
@media all and (max-width: 1024px) {
    .header-row:first-child {
        justify-content: flex-start;
    }
    .header-row:last-child {
        justify-content: flex-end;
    }
    .burger-menu__opener {
        display: flex;
    }
    .header-menu, .header__contacts {
        display: none;
    }
    .section-discount h4 {
        font-size: 18px;
    }
    .section__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }
    .room-wrapper, .activities-item__wrapper {
        display: flex;
        flex-direction: column;
    }
    .room-booking {
        grid-template-columns: 1fr 1fr;
    }
    .long-stay-item__wrapper {
        display: flex;
        flex-direction: column-reverse;
        gap: 30px;

    }
}
@media all and (max-width: 980px) {
    .helper {
        margin-top: 70px;
    }
    .section-main {
        height: calc(100vh - 130px);
    }
    .section-main__title {
        width: 100%;
    }
    .section-main__title h2 {
        font-size: 24px;
    }
    .section-main__title .lime {
        width: 120px;
    }
    .header-layout {
        height: 70px;
        grid-template-columns: 1fr 2fr 1fr;
        grid-gap: 10px;
        & .lang {
            display: none;
        }
    }
    .header-row .button {
        padding: 5px;
        font-weight: 600;
        letter-spacing: unset;
        height: 40px;
        font-size: 12px;
    }
    .logo a img {
        height: 60px;
    }
    .section-split {
        height: calc(100vh - 70px);
    }
    .split-content__title, .split-content--right .split-content__title {
        top: 60px;
        left: 50%;
        right: auto;
        width: 100%;
        padding: 0 15px;
        transform: translateX(-50%);
        text-align: center;
        & img {
            height: 50px;
        }
    }
    .split-content .lime {
        top: 100%;
        left: 50%;
        width: 80px;
        height: 80px;
    }
    .split-content--right .lime {
        top: 0;
    }
    .split-content__button {
        bottom: 60px;
        & .button {
            height: 50px;
            min-width: unset;
            font-size: 14px;
            color: #444444;
            font-weight: 500;
        }
    }
    .section-discount {
        padding: 15px;
        & h4 {
            font-size: 14px;
            line-height: 1.2;
            font-weight: 500;
        }
    }
    .section-booking {
        padding: 35px 32px 180px 32px;
        background-image: url(../img/backgrounds/lighthouse-mob.svg);
        background-position: bottom center;
        background-size: 80%;
    }
    .button i {
        font-size: 12px;
    }
    .form--availability {
        margin: 40px auto 0 auto;
        & .button--t1 {
            height: 50px;
            font-size: 14px;
            line-height: 17px;
            color: #444444;
        }
        & .form__input input {
            height: 50px;
            font-size: 14px;
        }
    }
    .select .selectric-wrapper .label {
        height: 50px;
        font-size: 14px;
        line-height: 50px;
    }
    .select .selectric-items li {
        height: 50px;
        font-size: 14px;
    }
    .slider-features .swiper-slide {
        flex-direction: column;
    }
    .swiper-half.swiper-container {
        height: 100vh;
    }
    .slider-features .swiper-slide {
        & .swiper-half-l {
            background-image: url(../img/backgrounds/glass-mob.svg), url(../img/backgrounds/leaf.svg);
            background-repeat: no-repeat;
            background-position: right 20px bottom 20px, left 40px top 40px;
            background-size: 140px, auto;
        }
    }

    .swiper-half .swiper-slide {

        & .swiper-half-l, .swiper-half-r {
            align-items: center;
            width: 100%;
            height: 50%;
        }
        & .swiper-half-l img {
            width: auto;
            height: 50px;
        }
    }
    .swiper-half__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 0;
    }
    .swiper-half__description {
        margin-top: 12px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
    }
    .swiper-half__navigation {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 16px;
    }
    .swiper-pagination.long .swiper-pagination-bullet {
        width: 30px;
    }

    .slider-activity .swiper-slide:first-of-type .swiper-half-l {
        background-position: bottom 100px right;
    }


    .section-services {
        padding: 35px 0;
    }
    .services-list {
        grid-template-columns: repeat(3, 1fr);
        margin-top: 15px;
        & i {
            width: 60px;
            height: 60px;
            font-size: 32px;
            margin-bottom: 12px;
        }
    }
    .service-list__name {
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        margin-bottom: 0;
    }
    .swiper-half__buttons .button {
        height: 50px;
        font-size: 16px;
    }
    .section-activity {
        padding-top: 0;
        & .section__title, .section__subtitle {
            display: none;
        }
        & .slider-activity {
            margin-top: 0;
            & .swiper-slide {
                flex-direction: column;
            }
        }
        & .swiper-half .swiper-slide .swiper-half-r i {
            top: auto;
            left: auto;
            right: 16px;
            bottom: 16px;
            width: 50px;
            height: 50px;
            font-size: 32px;
        }
        & .activity-menu {
            display: none;
        }
    }
    .section__subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        padding: 0 16px;
    }
    .section-feedback {
        padding: 35px 0;
        background-position: top 100px left, bottom 80px right 100px;
        background-size: 80px, 50px;
    }
    .feedback-slider__wrapper {
        padding: 0 16px;
        margin-top: 50px;
        & .feedback__title {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
        }
        & .feedback__rating {
            font-size: 10px;
        }
        & p {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #7D7C7C;
        }
    }
    .swiper-pagination__wrapper {
        bottom: 16px;
        z-index: 1;
        grid-template-columns: 1fr;
    }
    .section-faq {
        padding-top: 35px;
        &:after {
            right: 12px;
            bottom: 0;
            width: 200px;
            height: 190px;
            background-size: contain;
            background-position: bottom;
        }
    }
    .faq {

        margin-top: 20px;
    }
    .faq__question {
        font-size: 12px;
        line-height: 18px;
        & i {
            font-size: 6px;
        }
    }
    .faq__answer {
        font-size: 11px;
        line-height: 14px;
    }
    .footer {
        & .copyright {
            font-size: 8px;
            line-height: 12px;
        }
    }
    .footer-copyright .logo {
        margin-bottom: 8px;
    }
    .footer-layout {
        padding: 40px 0 12px 0;
        background-position: center bottom;
        background-size: 200px;
        & .footer-row {
            width: 100%;
            text-align: center;
        }
    }
    .footer__title {
        font-weight: 600;
        color: #FFFFFF;
    }
    .footer-layout--top {
        display: flex;
        flex-direction: column;
        gap: 10px;
        & .form__section {
            width: 100%;
        }
        & .socials {
            justify-content: center;
        }
    }
    //.footer-row {
    //    &:first-child {
    //        order: 0;
    //    }
    //    &:nth-child(2) {
    //        order: 1;
    //        margin-top: 70px;
    //        margin-bottom: 50px;
    //        text-align: center;
    //        & .socials {
    //            justify-content: center;
    //        }
    //    }
    //}
    input[type=text], input[type=password], input[type=email], input[type=tel], input[type=search], input[type=file], textarea {
        font-size: 14px;
    }
    .form--feedback .button--t1 {
        font-size: 14px;
    }
    .form--feedback .form__section--submit {
        display: flex;
        flex-direction: column;
    }

    .rooms-header, .activities-header {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 70px);
        background-position: bottom 40px right;
        background-size: 150px;


        & .rooms-header__content, .activities-header__content {
            padding: 40px;
            height: 50%;
            & h1 {
                font-weight: 500;
                font-size: 32px;
                line-height: 38px;
                text-align: center;
                margin-bottom: 32px;
            }
            & p {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 0;
                text-align: center;
                justify-content: center;
            }
        }
    }
    .activities-header {
        & .activities-header__img {
            background-size: cover;
            height: 50%;

            & img {
                height: 100%;
            }
        }
    }
    .activities-header__content {
        text-align: center;
        & .button {
            margin-top: 30px;
        }
    }
    .activities-item {
        display: flex;
        flex-direction: column-reverse;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
    .section-select-rooms {
        padding: 35px 32px 180px 32px;
        background-image: url(../img/backgrounds/lighthouse-mob.svg);
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: 80%;
    }
    .section-rooms {
        padding: 35px 0;
    }
    .rooms__list {
        grid-template-columns: 1fr;
        grid-gap: 35px;
    }
    .rooms-item__highlights {
        font-size: 24px;
    }
    .rooms-item__buttons {
        margin-top: 20px;
        & .button {
            width: 100%;
        }
    }
    .button--s2 {
        height: 50px;
    }
    .button--t1, .button--t4, .button--t3 {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;
    }
    .rooms-item__preview {
        height: 240px;
    }
    .section-promo {
        padding: 35px 16px;
        justify-content: center;
        height: auto;
        & h4 {
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
        }
        & .button--t2 {
            font-size: 14px;
            font-weight: 400;
        }
    }
    .booking__checkin, .booking__checkout {
        & .booking__date {
            font-size: 14px;
        }
        & span {
            font-size: 12px;
        }
    }
    .booking__buttons {
        grid-gap: 20px;
    }
    .section-activities {
        padding-top: 0;
    }
    .services-exception {
        padding: 0;
    }

}
@media all and (max-width: 768px) {
    .booking__wrapper {
        grid-template-columns: 1fr;
        &:before {
            content: none;
        }
    }
    .booking-steps {
        margin-bottom: 30px;
        &:before {
            width: 200px;
        }
        & .step {
            width: 30px;
            height: 30px;
            font-size: 16px;
            &.current:before {
                width: 42px;
                height: 42px;
            }
        }
    }
    .booking__details {
        font-size: 14px;
        font-weight: 400;
        & li {
            padding: 10px 0;
            &:last-child {
                padding-top: 15px;
                margin-top: 10px;
            }

        }
    }
    .booking__total {
        padding: 20px;
    }
    .booking__type {
        font-size: 14px;
    }
    .form--guest-info .form__input label {
        font-size: 14px;
        margin-bottom: 8px;
    }
    .form--guest-info .form__input:not(:last-child) {
        margin-bottom: 20px;
    }
    .form--guest-info textarea {
        height: 140px;
    }
    .booking__buttons {
        margin-top: 30px;
        grid-gap: 20px;
    }
    .form--guest-info input[type=text], .form--guest-info input[type=password], .form--guest-info input[type=email], .form--guest-info input[type=tel], .form--guest-info input[type=number], .form--guest-info input[type=search], .form--guest-info input[type=file], .form--guest-info textarea {
        font-size: 14px;
    }
    .booking-notification__success h4 {
        font-size: 20px;
    }
    .booking-notification__description {
        font-size: 14px;
        line-height: 1.2;
    }
    .activities-item__photos {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 2fr;
    }
    .activities__preview {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        & img {
            width: 100%;
        }
    }
}
@media all and (max-width: 680px) {
    .lang {
        font-size: 16px;
        line-height: 18px;
    }
    .page-room {
        padding-top: 35px;
        padding-bottom: 100px;
        background-position: bottom right 20px;
        background-size: 120px;
    }
    .page__title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
    }
    .breadcrumbs {
        font-size: 12px;
        margin-bottom: 16px;
    }
    .slider-room__wrapper {
        max-height: 280px;
        margin-bottom: 16px;
    }
    .slider-room-thumbs__wrapper {
        max-height: 120px;
        height: 100%;
        margin-bottom: 16px;
        padding: 8px;
    }
    .room-amenities {
        padding: 20px;
        margin-bottom: 16px;
    }
    .room-amenities__title, .room-rules__title {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .room-amenities__list {
        grid-gap: 10px;
        & li {
            & i {
                font-size: 24px;
                margin-right: 10px;
            }
            & span {
                font-size: 11px;
                line-height: 1;
            }
        }
    }
    .room-rules {
        padding: 20px;
    }
    .room-rules__time {
        margin-bottom: 10px;
        width: 100%;
        justify-content: space-between;
        & .check__title {
            font-size: 12px;
            line-height: 1;
        }
    }
    .room__price {
        padding: 8px;
        font-size: 10px;
        line-height: 1;
    }
    .room__price .price__volume {
        font-size: 14px;
        line-height: 1;
    }
    .room-rules__list {
        grid-template-columns: 1fr;

    }
    .booking-calendar {
        margin-bottom: 20px;
        padding: 20px;
    }
    .room-booking {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
    }
    .room-description__title, .room-safety__title {
        font-size: 18px;
        line-height: 20px;

    }
    .room-description {
        & p:last-child {
            margin-bottom: 0;
        }
    }
    .room-description, .room-safety__item {
        font-size: 14px;
    }
    .rooms-item__price {
        left: 10px;
        height: 40px;
        width: 100px;
    }
    .room-wrapper, .activities-item__wrapper {
        grid-gap: 20px;
    }
    .room-safety__list {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .form--booking {
        padding: 20px;
    }
    .form--booking .form__section--submit {
        margin-top: 20px;
    }
    .activities-item__photos {
        grid-gap: 10px;
    }
    .activities-item__title {
        font-size: 18px;
    }
    .activities-item__short-description {
        & p {
            font-size: 14px;
        }
    }
    .activities-item__params {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
    .activities-level {
        width: 100%;
        height: 40px;

    }
    .slider-activities-item__wrapper {
        margin-bottom: 10px;
    }
    .slider-activities-item-thumbs__wrapper {
        padding: 5px;
        margin-bottom: 10px;
    }
    .slider-activities-item-thumbs__wrapper .swiper .swiper-slide {
        border-radius: 5px;
    }
    .activities-item__characteristics .activities-param {
        margin-left: 0;
        height: 40px;
    }
    .activities-item__characteristics {
        padding: 20px;
        & h3 {
            font-size: 20px;
        }
    }
    .activities-item__text h1 {
        font-size: 20px;
        line-height: 1.2;
    }
    .long-stay__packages {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .long-stay-amenities {
        padding: 20px;
    }
    .long-stay-amenities__list {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }
    .long-stay-amenities__list span {
        font-size: 12px;
    }
    .long-stay-item__wrapper {
        padding-bottom: 30px;
    }
}

@media all and (max-width: 380px) {

}

@media all and (min-width: 2200px) {
    body {
        & > * {
            zoom: 120%;
        }
    }
}

@media all and (min-width: 2800px) {
    body {
        & > * {
            zoom: 150%;
        }
    }
}

@media all and (min-width: 3840px) {
    body {
        & > * {
            zoom: 200%;
        }
    }
}
