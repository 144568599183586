// Breakpoints
// ------------
$small: 0px;
$smedium: 400px;
$medium: 640px;
$large: 1024px;
$xlarge: 1200px;
$xxlarge: 1440px;
// ------------

// Media Queries
// ------------
@mixin breakpoint($size) {
    @media (min-width: $size) {
        @content;
    }
}

.section-split {
    position: relative;
    height: 890px;
}
.section-split__wrapper {
    display: block;
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 1;
    overflow: hidden;
    background-color: #000;
    @include breakpoint($large) {
        display: flex;
    }
}

.split-content {
    position: relative;
    display: block;
    width: 100%;
    height: 50%;
    overflow: hidden;
    @include breakpoint($large) {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 100%;
        height: 100%;
        transition: all 1.1s cubic-bezier(.8,0,.2,1);
    }
    &:hover {
        @include breakpoint($large) {
            flex: 3;
        }
        .split-content__image img {
            transform: scale(1);
        }
    }
    & .lime {
        position: absolute;
        top: 50%;
        left: 50vw;
        z-index: 2;
        width: 200px;
        transform: translate(-50%, -50%);
        height: 200px;
    }
    &--right {
        & .lime {
            right: calc(50vw - 200px);
            left: unset;
        }
        & .split-content__title {
            right: 110px;
            left: auto;
        }
        & .button--t3 {
            flex-direction: row-reverse;
            & i {
                margin-right: 0;
                margin-left: 12px;
            }
        }
    }
    &__image {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            transform: scale(1.2);
            transition: all 1s cubic-bezier(.8,0,.2,1);
            @supports not(object-fit: cover) {
                top: 50%; left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: auto;
            }
        }
    }
    &__title {
        position: absolute;
        top: 35px;
        left: 110px;
        text-align: center;
        h2 {
            padding: 0;
            margin-top: 24px;
            color: #FFFFFF;
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
        }
        & img {
            height: 120px;
        }
    }
    &__button {
        position: absolute;
        bottom: 135px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        padding: 0 40px;
    }
}

.choose {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
    text-align: center;
}