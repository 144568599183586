.modal {
    position: relative;
    background: #FFFFFF;
    border-radius: 24px;
    overflow: hidden;
    max-width: 680px;
    text-align: center;
    & .button--close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #EFF3F6;
        &:hover {
            color: #009FF5;
        }
    }
    &__wrapper {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1043;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 24px 16px;
        background: rgb(55 73 87 / 90%);
        & .form__description {
            display: flex;
            text-align: left;
            font-size: 12px;
            line-height: 16px;
            color: #009FF5;
            max-width: 248px;
            margin: auto;
            & i {
                font-size: 24px;
                margin-right: 8px;
            }
            & p {
                margin-bottom: 0;
            }
        }
    }
    &__title {
        margin-bottom: 32px;
        text-align: center;
        & h3 {
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 0;
        }
    }
    &__content {
        padding: 60px;
        & .form__section {
            text-align: center;

            & .button {
                max-width: 100%;
            }
        }

    }
    &--quote {
        & .modal__content {
            padding: 24px 40px;
            text-align: center;
        }
        & .modal__description {
            max-width: 400px;
            margin: 0 auto 40px auto;
            font-size: 18px;
            line-height: 24px;
            & p {
                margin-bottom: 0;
            }
        }
        & .checkbox {
            text-align: center;
            justify-content: center;
            align-items: center;
            margin: 40px auto 0 auto;
        }

    }
}