.faq {

    width: 100%;
    text-align: left;
    margin-top: 60px;
    &--open {
        & .faq__answer {
            visibility: visible;
            opacity: 1;
            height: auto;
            padding: 0 16px 16px 36px;
        }
        & .faq__question {
            & i {
                transform: rotate(0deg);
            }
        }
    }
    &__item {
        padding: 0 128px;
        &:nth-child(odd) {
            background: #F5F5F5;
        }

    }
    &__question {
        display: flex;
        padding: 16px 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #7D7C7C;
        cursor: pointer;
        & i {
            font-size: 10px;
            color: #7D7C7C;
            margin-right: 20px;
            transition: transform .4s ease-out;
            transform: rotate(-90deg);
        }
        & p {
            margin-bottom: 0;
        }
    }
    &__answer {
        opacity: 0;
        visibility: hidden;
        height: 0;
        font-size: 16px;
        line-height: 20px;
        color: #7D7C7C;
        transition: opacity 1s ease-out;
        & p {
            margin-bottom: 0;
        }
    }
}