//forms----------------------------------------------------------------
.form {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        width: 464px;
        max-width: 100%;
        background: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(50px);
        border-radius: 24px;
        padding: 60px 40px;
    }
    &--feedback {
        & .button--t1 {
            letter-spacing: normal;
            font-weight: 300;
        }
    }
    &--guest-info {
        & .form__input, .form__section {
            width: 100%;
            max-width: 100%;
        }
        & .form__input {
            &:not(:last-child) {
                margin-bottom: 30px;
            }
            & label {
                color: #152C5B;
                margin-bottom: 15px;
            }
        }
        & input[type=text], input[type=password], input[type=email], input[type=tel], input[type=number], input[type=search], input[type=file], textarea {
            background: #F5F6F8;
            font-size: 16px;
        }
        & textarea {
            height: 240px;
        }
        .form__input--date {
            & label {
                color: #70E8A0;
            }
            & i {
                top: auto;
                bottom: 0;
            }
        }
    }
    &__addition {
        margin-top: 24px;

        & a {
            color: #009FF5;
        }
    }
    &__links {
        width: 100%;
        justify-content: space-around;
        display: flex;
        max-width: 300px;
        margin-top: 32px;
        & a {
            color: #009FF5;
        }
    }

    &__heading {
        width: 100%;
        margin-bottom: 42px;
    }
    &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #7D7C7C;
        text-align: center;
        margin-bottom: 30px;
    }
    &__subtitle {
        margin-top: 12px;
        text-align: center;
    }
    &__section {
        width: 460px;
        max-width: 100%;
        &:not(:last-child) {
            margin-bottom: 16px;
        }
        &--submit {
            text-align: center;
        }
        &-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        &-title {
            margin: 0 0 24px 0;
            font-weight: 700;
            font-size: $font-size-default;
            text-transform: uppercase;
            line-height: 28px;
            text-align: center;
        }
    }
    &__notification {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        padding: 4px 12px;
        margin-top: 8px;
        border-radius: 6px;
        & a {
            text-decoration-line: underline;
            margin-left: 4px;
            &:hover {
                text-decoration-line: none;
            }
        }
        & i {
            font-size: 20px;
            margin-right: 18px;
        }
        &--error {
            color: #E6434F;
            background: #FFEBEE;

        }
        &--success {
            color: $color-success;
        }

    }

    .forgot-link {
        font-size: 12px;
        color: #009FF5;
        text-align: right;
        width: 100%;
        display: block;
        &:hover {
            text-decoration-line: none;
        }
    }
}
.input-group {
    &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 8px;
    }
}
.pass-switch {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: fit-content;
    height: 54px;
    font-size: 20px;
    color: #577084;
    transition: $t-color;
    cursor: pointer;
    padding-inline: 15px;
}
.form__input label ~ .pass-switch {
    top: 23px;
}

    //inputs----------------------------------------------------------------
input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
input[type='number'],
input[type='search'],
input[type='file'],
textarea {
    width: 100%;
    height: 50px;
    padding: 8px 16px;
    font-size: 20px;
    font-weight: 300;
    line-height: 20px;
    color: #444444;
    background: $input-background;
    border: 1px solid transparent;
    border-radius: 10px;
    box-sizing: border-box;
    transition: all 0.4s;
    flex-shrink: 0;
    text-overflow: ellipsis;
    font-family: $font-primary;
    &::placeholder {
        color: rgba(0, 0, 0, 0.5);
        transition: all 0.4s;
        text-overflow: ellipsis;
    }
    &:focus::placeholder {
        opacity: 0;
    }
    &:focus {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    }
}
textarea {
    width: 100%;
    max-width: 100%;
    height: 100px;
    padding: 16px;
    border-radius: 10px;
    line-height: 1.5;
    overflow: auto;
    box-sizing: border-box;
    resize: none;
    font-family: $font-primary;
}

.form__input {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 500px;
    &:not(:last-child) {
        margin-bottom: 16px;
    }
    &.short {
        width: 190px;
    }
    & label {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 16px;
        color: #7D7C7C;
    }
    &.error {
        & label {
            color: #E6434F;
        }
        & input {
            border-color: #E6434F;
        }
    }
    &--file {
        input[type='file'] {
            position: absolute;
            opacity: 0;
        }
        input[type='text'] {
            pointer-events: none;
        }
    }

    &--date {
        position: relative;
        & i {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 26px;
            color: rgba(0, 0, 0, 0.5);
            pointer-events: none;
        }
    }
    &--increment {
        max-width: 100%;
        & .button {
            position: absolute;
            bottom: 0;
            width: 50px;
            border-radius: 8px;
            color: #FFFFFF;
            padding: 0;
            font-size: 34px;
            font-weight: 600;

            &.minus {
                left: 0;
                background: #7D7C7C;
            }
            &.plus {
                right: 0;
                background: #70E8A0;
            }
        }
    }
}
.form__input input[type='text']:disabled,
.form__input input[type='password']:disabled,
.form__input input[type='email']:disabled,
.form__input input[type='tel']:disabled,
.form__input textarea:disabled {
    color: $color-disabled;
    border-color: $color-disabled;
    cursor: default;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}
.input__description {
    font-size: 14px;
    line-height: 25px;
    color: #666669;
    max-width: 500px;
}
.checkbox {
    text-align: left;
    input[type="checkbox"] {
        display: none;
        height: 0;
        visibility: hidden;
        & + label {
            position: relative;
            display: inline-block;
            width: auto;
            padding-left: 26px;
            min-height: 18px;
            font-size: 12px;
            line-height: 1.5;
            font-weight: 500;
            color: #577084;
            cursor: pointer;
            &::before {
                content: '';
                position: absolute;
                top: 3px;
                left: 0;
                width: 16px;
                height: 16px;
                border: 2px solid #7D7C7C;
                border-radius: 4px;
                transform: translateY(-2px);
                transition: 0.3s;
            }
            &::after {
                content: '';
                position: absolute;
                top: 4px;
                left: 3px;
                width: 10px;
                height: 6px;
                border-bottom: 2px solid #FFFFFF;
                border-left: 2px solid #FFFFFF;
                transform: rotate(-45deg);
                opacity: 0;
                outline: none;
                transition: opacity 0.3s;
            }
        }
    }
    input[type="checkbox"]:checked {
        & + label {
            &::after {
                opacity: 1;
            }
            &::before {
                background: #374957;
                border-color: #374957;
            }
        }
    }
    input[type="checkbox"]:disabled {
        & + label {
            cursor: default;
            &:after {
                border-color: #fff;
            }
            &:before {
                background-color: #a6a6a6;
                border-color: #a6a6a6;
            }
        }
    }
    a {
        margin: 0 2px;
        text-decoration-line: underline;
        color: #0755B1;
        &:hover {
            text-decoration-line: none;
        }
    }
    &--circle {
        width: 100%;
        min-height: 54px;
        padding: 8px 16px;
        color: #260303;
        border: 1px solid #D6E0E8;
        border-radius: 12px;
        & input[type=checkbox] + label::before {
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            left: unset;
            width: 20px;
            height: 20px;
            border-radius: 10px;
        }
        & input[type=checkbox]:checked + label {
            background: #FFDE57;
        }
        & input[type=checkbox]:checked + label::before {
            border-color: #FFFFFF;
            background: transparent;
        }
        & input[type=checkbox]:checked + label::after {
            left: unset;
            right: 5px;
            top: calc(50% - 2px);
            transform: translateY(-50%) rotate(-45deg);
        }
        & input[type=checkbox] + label {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 0;
            font-size: 16px;
            padding-right: 42px;
            line-height: 1.5;
            padding-left: 0;
            user-select: none;
        }
    }
}


//selects----------------------------------------------------------------
.select {
    &-wrapper {
        display: flex;
        max-width: 360px;
        justify-content: flex-start;
    }

    .selectric {
        width: auto;
        border: none;
        border-radius: 10px;
        box-sizing: border-box;
        transition: 0.4s;
        background: #ffffff;
        .icon-arrow-down {
            @include dropdownIconMixin;
        }
        &-open {
            .icon-arrow-down {
                @include dropdownIconOpenedMixin;
            }
        }
        &-wrapper {
            //width: 100%;
            min-width: 100%;
            .label {
                width: 100%;
                height: 80px;
                margin: 0;
                color: #444444;
                font-weight: 300;
                line-height: 80px;
                padding: 0 40px 0 25px;
                font-size: 20px;
                text-align: left;
                transition: $t-opacity;
            }
        }
        &--placeholder {
            .label {
                color: $color-text;
            }
        }
        &-items {
            overflow: hidden;
            li {
                font-weight: 300;
                font-size: 20px;
                line-height: 27px;
                height: 80px;
                display: flex;
                align-items: center;
                padding: 0 25px;
                transition: all .3s;

            }
        }
        &-disabled {
            opacity: 1;
            .selectric {
                .label {
                    color: $color-disabled;
                }
                &__button {
                    &:before, &:after {
                        background: $color-disabled;
                    }
                }

            }
        }
    }
}

// radio----------------------------------------------------------------

.radio {
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        + .radio-label {
            height: 80px;
            width: 100%;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            border-radius: 10px;
            cursor: pointer;

            padding: 0 16px;
            transition: all 250ms ease;

            & span {
                font-weight: 600;
                font-size: 18px;
                color: #52503B;
            }
            & a {
                font-weight: 500;
                font-size: 12px;
                text-decoration-line: underline;
                color: #52503B;
                margin-top: 12px;
                &:hover {
                    text-decoration-line: unset;
                }
            }

            &:before {
                content: '';
                border-radius: 100%;
                border: 2px solid #7D7C7C;
                display: inline-block;
                width: 20px;
                height: 20px;
                position: relative;
                vertical-align: top;
                cursor: pointer;
                text-align: center;
                transition: all 250ms ease;
            }
        }
        &:checked {
            + .radio-label {
                background: #FFDE57;
                &:before {
                    background: #FFFFFF;
                    box-shadow: inset 0 0 0 4px #FFDE57;
                    border: 2px solid #FFFFFF;
                }
            }
        }
        &:focus {
            + .radio-label {
                &:before {
                    outline: none;
                    border-color: #FFFFFF;
                }
            }
        }
        &:disabled {
            + .radio-label {
                &:before {
                    box-shadow: inset 0 0 0 4px #FFDE57;
                    border-color: darken(#FFDE57, 25%);
                    background: darken(#FFDE57, 25%);
                }
            }
        }
        + .radio-label {
            &:empty {
                &:before {
                    margin-right: 0;
                }
            }
        }
    }
    &-label__text {
        display: flex;
        flex-direction: column;
    }
}

.form__input--switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    border-bottom: 1px solid #EAEFF3;
}

.switch {
    display: inline-block;
    width: 44px;
    height: 24px;
    position: relative;
    background-color: #EFF3F6;
    border-radius: 12px;
    border: 1px solid #A7BAC9;
    appearance: none;
    user-select: none;
    cursor: pointer;
    transition: all .4s;
}

.switch::before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 3px;
    top: 2px;
    background-color: #A7BAC9;
    border-radius: 50%;
    transition: all .4s;
}

.switch:checked {
    background-color: #E5FCE5;
    border-color: #59CA56;
}

.switch:checked::before {
    transform: translateX(20px);
    background-color: #59CA56;
}

.switch:disabled {
    background-color: hsl(0deg 0% 70%);
    cursor: default;
}

.switch:disabled::before {
    background-color: hsl(0deg 0% 90%);
}

.radio-switch {
    flex-direction: row;
    & label {
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42px;
        width: 50%;
        border: 1px solid #D6E0E8;
        cursor: pointer;
        &:first-of-type {
            border-radius: 12px 0 0 12px;
        }
        &:last-of-type {
            border-radius: 0 12px 12px 0;
        }
    }
    & input {
        display: none;
        visibility: hidden;
        &:checked + label {
            color: #FFFFFF;
            background: #59CA56;
            border-color: #59CA56;
        }
    }
}
.form-group {
    width: 100%;
    margin-bottom: 12px;
}