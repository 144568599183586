.footer-menu {
    display: flex;
    flex-direction: column;

    cursor: pointer;
    &__item {
        margin-bottom: 10px;
    }
    &__item-link {
        transition: $t-opacity;
        &:hover {
            opacity: .4;
        }
    }

}
