.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-weight: 400;
    user-select: none;
    padding: 16px 36px;
    color: $color-text;
    text-decoration: none;
    transition: all 0.2s;
    cursor: pointer;
    border-radius: 10px;
    &-link {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        transition: all 0.2s;
        &.swiper-button-disabled {
            opacity: 0;
        }
        & i {
            margin-left: 12px;
        }
        &:hover {
            text-shadow: 3px 3px 0 rgba(125, 124, 124, 0.7);
        }
    }
    & i {
        margin-right: 12px;
        font-size: 16px;
    }
    &:hover {
        transition: all 0.2s;
    }
    &:disabled {
        background: #EAEFF3;
        cursor: default !important;
        color: #D6E0E8;
        &:hover {
            background: #EAEFF3;
        }
    }
    &.disabled {
        background: #EAEFF3;
        cursor: default;
        color: #D6E0E8;
    }

    &--s1 {
        max-width: 260px;
        //height: 80px;
        width: 100%;
    }
    &--s2 {
        width: auto;
        height: 80px;
        min-width: 280px;
        padding: 0 26px;

    }
    &--s3 {
        width: 116px;
        height: 32px;
    }
    &--s4 {
        width: 320px;
        height: 60px;
    }
    &--t1 {
        position: relative;
        background: #FFDE57;
        font-weight: 600;
        z-index: 1;
        &:hover {
            box-shadow: 5px 5px 0 rgba(125, 124, 124, 0.7);
            transform: translate(-2px, -2px);
        }
        &:active {
            transform: translate(0px, 0px);
            box-shadow: 1px 1px 3px rgba(125, 124, 124, 0.7);
        }
        &.disabled {
            background: #EAEFF3;
            cursor: default;
            color: #D6E0E8;
        }
    }
    &--t2 {
        background: transparent;
        border: 2px solid #FFFFFF;
        color: #FFFFFF;
        font-weight: 600;
        &:hover {
            background: #FFFFFF;
            color: rgba(70, 150, 155, 0.59);
            box-shadow: 5px 5px 0 rgba(125, 124, 124, 0.7);
            transform: translate(-2px, -2px);
        }
        &:active {
            transform: translate(0px, 0px);
            box-shadow: 1px 1px 3px rgba(125, 124, 124, 0.7);
        }
        &.disabled {
            background: #EAEFF3;
            cursor: default;
            color: #D6E0E8;
        }
    }
    &--t3 {
        background: #70E8A0;
        color: #7D7C7C;
        font-weight: 600;

        &:hover {
            box-shadow: 5px 5px 0 rgba(125, 124, 124, 0.7);
            transform: translate(-2px, -2px);
        }
        &:active {
            transform: translate(0px, 0px);
            box-shadow: 1px 1px 3px rgba(125, 124, 124, 0.7);
        }
        &.disabled {
            background: #EAEFF3;
            cursor: default;
            color: #D6E0E8;
        }
    }
    &--t4 {
        background: #E5E5E5;
        font-weight: 600;
        &:hover {
            box-shadow: 5px 5px 0 rgba(125, 124, 124, 0.7);
            transform: translate(-2px, -2px);
        }
        &:active {
            transform: translate(0px, 0px);
            box-shadow: 1px 1px 3px rgba(125, 124, 124, 0.7);
        }
        &.disabled {
            background: #EAEFF3;
            cursor: default;
            color: #D6E0E8;
        }
    }


}
.back-link {
    display: flex;
    align-items: center;
    color: #577084;
    font-size: 18px;
    font-weight: 500;
    & i {
        font-size: 24px;
        margin-right: 4px;
    }
}
.read-more__button {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color .4s;
    & i {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #7D7C7C;
        margin-left: 12px;
        width: 48px;
        height: 48px;
        border-radius: 24px;
    }
    &:hover {
        color: #0b0b0b;
    }
}