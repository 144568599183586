/* -------------------------------------------------
    Datepicker body
   ------------------------------------------------- */

.air-datepicker-body {
  transition: all var(--adp-transition-duration) var(--adp-transition-ease);

  &.-hidden- {
    display: none;
  }
}


/*  Day names
   ------------------------------------------------- */

.air-datepicker-body--day-names {
  display: grid;
  grid-template-columns: repeat(7, var(--adp-day-cell-width));
  margin: 8px 0 3px;
}

.air-datepicker-body--day-name {
  color: var(--adp-day-name-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: .8em;

  &.-clickable- {
    cursor: pointer;

    &:hover {
      color: var(--adp-day-name-color-hover);
    }
  }
}

/*  Cells container
   ------------------------------------------------- */

.air-datepicker-body--cells {
  display: grid;
  &.-days- {
    grid-template-columns: repeat(7, var(--adp-day-cell-width));
    grid-auto-rows: var(--adp-day-cell-height);
  }

  &.-months- {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: var(--adp-month-cell-height);
  }

  &.-years- {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: var(--adp-year-cell-height);
  }
}
