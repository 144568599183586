.services {
    &-list {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 30px;
        margin-top: 40px;
        padding: 0 160px;
        &__item {

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        & i {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 80px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 10px;
            font-size: 48px;
            margin-bottom: 20px;
        }
        &__name {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #7D7C7C;
        }
    }
    &-exception {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        color: #52503B;
        padding: 0 160px;
        margin-top: 40px;
        font-size: 14px;
        & span {
            display: block;
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }

}
.service-list__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #7D7C7C;
    margin-bottom: 0;
}