@charset "UTF-8";
/***
*
* iconfont.scss генерируется динамечески со списком классов для иконочного шрифта
* _iconfont-template.styl - шаблон для генерации файла iconfont.styl
*
***/
/* Генерация рандомного числа, для обнуления кеша при деплое на песок и прод */
@font-face {
  font-family: "iconfont";
  src: url("../fonts/iconfont/iconfont.eot");
  src: url("../fonts/iconfont/iconfont.eot?#iefix") format("eot"), url("../fonts/iconfont/iconfont.woff2") format("woff2"), url("../fonts/iconfont/iconfont.woff") format("woff"), url("../fonts/iconfont/iconfont.ttf") format("truetype"), url("../fonts/iconfont/iconfont.svg?#iconfont") format("svg");
}
[class^=icon-], [class*=" icon-"] {
  display: flex;
  justify-content: center;
  align-items: center;
}
[class^=icon-]::before, [class*=" icon-"]::before {
  font-family: "iconfont";
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
}

.icon-air_conditioning::before {
  content: "\e001";
}

.icon-area::before {
  content: "\e002";
}

.icon-arrow-left::before {
  content: "\e003";
}

.icon-arrow-right::before {
  content: "\e004";
}

.icon-arrow::before {
  content: "\e005";
}

.icon-balcony::before {
  content: "\e006";
}

.icon-bathroom::before {
  content: "\e007";
}

.icon-bed::before {
  content: "\e008";
}

.icon-bed_single::before {
  content: "\e009";
}

.icon-bedroom_ensuite::before {
  content: "\e00a";
}

.icon-bill::before {
  content: "\e00b";
}

.icon-breakfast::before {
  content: "\e00c";
}

.icon-calendar::before {
  content: "\e00d";
}

.icon-check::before {
  content: "\e00e";
}

.icon-cleaning::before {
  content: "\e00f";
}

.icon-cleaning_included::before {
  content: "\e010";
}

.icon-close::before {
  content: "\e011";
}

.icon-coffe::before {
  content: "\e012";
}

.icon-conditioner::before {
  content: "\e013";
}

.icon-dinner::before {
  content: "\e014";
}

.icon-group::before {
  content: "\e015";
}

.icon-hair_dryer::before {
  content: "\e016";
}

.icon-hangers::before {
  content: "\e017";
}

.icon-heating::before {
  content: "\e018";
}

.icon-hiking::before {
  content: "\e019";
}

.icon-kitchen-room::before {
  content: "\e01a";
}

.icon-kitchen::before {
  content: "\e01b";
}

.icon-laundry::before {
  content: "\e01c";
}

.icon-lime::before {
  content: "\e01d";
}

.icon-locker::before {
  content: "\e01e";
}

.icon-lockers::before {
  content: "\e01f";
}

.icon-menu::before {
  content: "\e020";
}

.icon-phone::before {
  content: "\e021";
}

.icon-restaurant::before {
  content: "\e022";
}

.icon-route::before {
  content: "\e023";
}

.icon-routes::before {
  content: "\e024";
}

.icon-security::before {
  content: "\e025";
}

.icon-shared_kitchen::before {
  content: "\e026";
}

.icon-star-empty::before {
  content: "\e027";
}

.icon-star::before {
  content: "\e028";
}

.icon-sup::before {
  content: "\e029";
}

.icon-surfboard::before {
  content: "\e02a";
}

.icon-temperature::before {
  content: "\e02b";
}

.icon-timer::before {
  content: "\e02c";
}

.icon-towels::before {
  content: "\e02d";
}

.icon-transfer::before {
  content: "\e02e";
}

.icon-tv::before {
  content: "\e02f";
}

.icon-up::before {
  content: "\e030";
}

.icon-washing-machine::before {
  content: "\e031";
}

.icon-wifi::before {
  content: "\e032";
}

.icon-yoga::before {
  content: "\e033";
}

.icon-airbnb::before {
  content: "\e034";
}

.icon-facebook::before {
  content: "\e035";
}

.icon-instagram::before {
  content: "\e036";
}

.icon-telegram::before {
  content: "\e037";
}

.icon-tiktok::before {
  content: "\e038";
}

.icon-twitter::before {
  content: "\e039";
}

.icon-youtube::before {
  content: "\e03a";
}

/* arrow */
/* text overflow ellipsis */
html,
body {
  overflow-x: hidden;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  min-height: 100vh;
  text-decoration-skip: none;
}

iframe {
  border: 0;
}

main {
  display: block;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

li {
  display: block;
}

dl {
  margin-top: 0;
  margin-bottom: 0;
}

dd {
  margin-left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: inherit;
}

blockquote {
  margin: 0;
  padding: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

sup {
  position: relative;
  top: -0.5em;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

strong {
  font-weight: bold;
}

figure {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.5s;
}

a,
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

button {
  overflow: visible;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  text-align: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  font: inherit;
  cursor: pointer;
  -webkit-font-smoothing: inherit;
}

button,
[type=text],
[type=password],
[type=submit],
[type=search],
[type=email],
[type=tel],
textarea {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

th {
  vertical-align: middle;
}

td {
  vertical-align: middle;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: 0;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

input[type=radio],
input[type=checkbox] {
  margin: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent;
  border: 1px solid #D6E0E8;
  border-bottom: 1px solid #D6E0E8;
  -webkit-text-fill-color: #707585;
  transition: background-color 5000s ease-in-out 0s;
}

input:-internal-autofill-selected,
input:-internal-autofill-previewed {
  color: #7D7C7C !important;
  background-color: #FFFFFF !important;
  -webkit-box-shadow: 0 0 0 1000px #FFFFFF inset !important;
  -webkit-text-fill-color: #000000 !important;
}

html,
body,
.mobile-nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar,
.mobile-nav::-webkit-scrollbar {
  display: none;
}

body {
  color: #7D7C7C;
  transition: color 0.4s;
  background-color: #FFFFFF;
}
body.fixed {
  overflow: hidden;
  height: 100vh;
}
body.blur .main {
  filter: blur(3px);
  transition: all 0.3s ease-out;
}

.helper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  margin-top: 100px;
  background-color: var(--background-body);
}

.main {
  position: relative;
  z-index: 1;
}

.container {
  margin: 0 auto;
  padding: 0 100px;
  max-width: 1920px;
  width: 100%;
}

.section {
  text-align: center;
}
.section__title {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  margin-bottom: 20px;
  color: #52503B;
}
.section__subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #52503B;
}
.section-main {
  position: relative;
  height: calc(100vh - 140px);
  overflow: hidden;
}
.section-main__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
}
.section-main__title h2 {
  font-weight: 500;
}
.section-main__title .lime {
  width: 200px;
}
.section-main__content {
  height: 100%;
}
.section-main__image {
  width: 100%;
  height: 100%;
}
.section-main__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.section-discount {
  background: #EBFFEB;
  padding: 6px 100px;
}
.section-discount h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #7D7C7C;
  margin-bottom: 0;
}
.section-booking {
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #F5F5F5;
  background-image: url(../img/backgrounds/lighthouse.svg);
  background-repeat: no-repeat;
  background-position: bottom -100px right 100px;
  text-align: center;
}
.section-select-rooms {
  padding: 60px 0;
  background-color: #F5F5F5;
}
.section-select-rooms.hide {
  display: none;
}
.section-rooms {
  padding: 60px 0;
}
.section-promo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 300px;
  height: 400px;
  background-image: url(../img/backgrounds/promo-surf.jpg);
}
.section-services {
  padding: 60px 0;
  background-color: #F5F5F5;
}
.section-activity {
  padding-top: 60px;
}
.section-feedback {
  padding: 78px 0;
  background-color: #FFDE57;
  background-image: url(../img/backgrounds/lime-white.svg), url(../img/backgrounds/slice-white.svg);
  background-repeat: no-repeat;
  background-position: top 50px left, top 100px right 200px;
  text-align: center;
}
.section-faq {
  position: relative;
  padding-top: 64px;
  padding-bottom: 40px;
}
.section-faq:after {
  content: "";
  position: absolute;
  right: 180px;
  bottom: 0;
  width: 320px;
  height: 190px;
  background-image: url("../img/backgrounds/letter.svg");
  background-repeat: no-repeat;
}
.section-activities {
  padding: 60px 0;
}
.section__buttons {
  margin: 20px auto;
}

.tabs-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.tabs__item {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFDC69;
  font-size: 14px;
  padding: 0 20px;
  text-transform: uppercase;
  font-weight: 700;
  height: 48px;
  cursor: pointer;
  transition: color 0.2s ease-out;
}
.tabs__item:hover {
  color: #52503B;
}
.tabs__item.active {
  color: #FFDC69;
  cursor: default;
}

.tooltip {
  position: absolute;
  top: calc(-100% - 6px);
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  padding: 8px 16px;
  background: #009FF5;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  white-space: nowrap;
  transition: opacity 0.4s;
}
.tooltip:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  bottom: -5px;
  display: block;
  width: 10px;
  height: 10px;
  background-color: #009FF5;
}

html {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: normal;
  user-select: none;
  margin-bottom: 24px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

h1 {
  display: block;
  width: 100%;
  font-size: 36px;
  line-height: 44px;
}

h2 {
  display: block;
  width: 100%;
  font-size: 40px;
  font-weight: 800;
  line-height: 1.1;
}

h3 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 16px;
}

h4 {
  font-size: 16px;
  font-weight: 600;
}

h5 {
  font-size: 16px;
  font-weight: 500;
}

h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

p {
  margin-bottom: 16px;
  line-height: 1.5;
}

li,
a {
  user-select: none;
  transition: color 0.2s;
}

i {
  transition: color 0.2s;
}

b {
  font-weight: 600;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-weight: 400;
  user-select: none;
  padding: 16px 36px;
  color: #7D7C7C;
  text-decoration: none;
  transition: all 0.2s;
  cursor: pointer;
  border-radius: 10px;
}
.button-link {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  transition: all 0.2s;
}
.button-link.swiper-button-disabled {
  opacity: 0;
}
.button-link i {
  margin-left: 12px;
}
.button-link:hover {
  text-shadow: 3px 3px 0 rgba(125, 124, 124, 0.7);
}
.button i {
  margin-right: 12px;
  font-size: 16px;
}
.button:hover {
  transition: all 0.2s;
}
.button:disabled {
  background: #EAEFF3;
  cursor: default !important;
  color: #D6E0E8;
}
.button:disabled:hover {
  background: #EAEFF3;
}
.button.disabled {
  background: #EAEFF3;
  cursor: default;
  color: #D6E0E8;
}
.button--s1 {
  max-width: 260px;
  width: 100%;
}
.button--s2 {
  width: auto;
  height: 80px;
  min-width: 280px;
  padding: 0 26px;
}
.button--s3 {
  width: 116px;
  height: 32px;
}
.button--s4 {
  width: 320px;
  height: 60px;
}
.button--t1 {
  position: relative;
  background: #FFDE57;
  font-weight: 600;
  z-index: 1;
}
.button--t1:hover {
  box-shadow: 5px 5px 0 rgba(125, 124, 124, 0.7);
  transform: translate(-2px, -2px);
}
.button--t1:active {
  transform: translate(0px, 0px);
  box-shadow: 1px 1px 3px rgba(125, 124, 124, 0.7);
}
.button--t1.disabled {
  background: #EAEFF3;
  cursor: default;
  color: #D6E0E8;
}
.button--t2 {
  background: transparent;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
  font-weight: 600;
}
.button--t2:hover {
  background: #FFFFFF;
  color: rgba(70, 150, 155, 0.59);
  box-shadow: 5px 5px 0 rgba(125, 124, 124, 0.7);
  transform: translate(-2px, -2px);
}
.button--t2:active {
  transform: translate(0px, 0px);
  box-shadow: 1px 1px 3px rgba(125, 124, 124, 0.7);
}
.button--t2.disabled {
  background: #EAEFF3;
  cursor: default;
  color: #D6E0E8;
}
.button--t3 {
  background: #70E8A0;
  color: #7D7C7C;
  font-weight: 600;
}
.button--t3:hover {
  box-shadow: 5px 5px 0 rgba(125, 124, 124, 0.7);
  transform: translate(-2px, -2px);
}
.button--t3:active {
  transform: translate(0px, 0px);
  box-shadow: 1px 1px 3px rgba(125, 124, 124, 0.7);
}
.button--t3.disabled {
  background: #EAEFF3;
  cursor: default;
  color: #D6E0E8;
}
.button--t4 {
  background: #E5E5E5;
  font-weight: 600;
}
.button--t4:hover {
  box-shadow: 5px 5px 0 rgba(125, 124, 124, 0.7);
  transform: translate(-2px, -2px);
}
.button--t4:active {
  transform: translate(0px, 0px);
  box-shadow: 1px 1px 3px rgba(125, 124, 124, 0.7);
}
.button--t4.disabled {
  background: #EAEFF3;
  cursor: default;
  color: #D6E0E8;
}

.back-link {
  display: flex;
  align-items: center;
  color: #577084;
  font-size: 18px;
  font-weight: 500;
}
.back-link i {
  font-size: 24px;
  margin-right: 4px;
}

.read-more__button {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.4s;
}
.read-more__button i {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #7D7C7C;
  margin-left: 12px;
  width: 48px;
  height: 48px;
  border-radius: 24px;
}
.read-more__button:hover {
  color: #0b0b0b;
}

.form {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 464px;
  max-width: 100%;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(50px);
  border-radius: 24px;
  padding: 60px 40px;
}
.form--feedback .button--t1 {
  letter-spacing: normal;
  font-weight: 300;
}
.form--guest-info .form__input, .form--guest-info .form__section {
  width: 100%;
  max-width: 100%;
}
.form--guest-info .form__input:not(:last-child) {
  margin-bottom: 30px;
}
.form--guest-info .form__input label {
  color: #152C5B;
  margin-bottom: 15px;
}
.form--guest-info input[type=text], .form--guest-info input[type=password], .form--guest-info input[type=email], .form--guest-info input[type=tel], .form--guest-info input[type=number], .form--guest-info input[type=search], .form--guest-info input[type=file], .form--guest-info textarea {
  background: #F5F6F8;
  font-size: 16px;
}
.form--guest-info textarea {
  height: 240px;
}
.form--guest-info .form__input--date label {
  color: #70E8A0;
}
.form--guest-info .form__input--date i {
  top: auto;
  bottom: 0;
}
.form__addition {
  margin-top: 24px;
}
.form__addition a {
  color: #009FF5;
}
.form__links {
  width: 100%;
  justify-content: space-around;
  display: flex;
  max-width: 300px;
  margin-top: 32px;
}
.form__links a {
  color: #009FF5;
}
.form__heading {
  width: 100%;
  margin-bottom: 42px;
}
.form__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #7D7C7C;
  text-align: center;
  margin-bottom: 30px;
}
.form__subtitle {
  margin-top: 12px;
  text-align: center;
}
.form__section {
  width: 460px;
  max-width: 100%;
}
.form__section:not(:last-child) {
  margin-bottom: 16px;
}
.form__section--submit {
  text-align: center;
}
.form__section-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form__section-title {
  margin: 0 0 24px 0;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 28px;
  text-align: center;
}
.form__notification {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 12px;
  margin-top: 8px;
  border-radius: 6px;
}
.form__notification a {
  text-decoration-line: underline;
  margin-left: 4px;
}
.form__notification a:hover {
  text-decoration-line: none;
}
.form__notification i {
  font-size: 20px;
  margin-right: 18px;
}
.form__notification--error {
  color: #E6434F;
  background: #FFEBEE;
}
.form__notification--success {
  color: #239024;
}
.form .forgot-link {
  font-size: 12px;
  color: #009FF5;
  text-align: right;
  width: 100%;
  display: block;
}
.form .forgot-link:hover {
  text-decoration-line: none;
}

.input-group__title {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 8px;
}

.pass-switch {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  height: 54px;
  font-size: 20px;
  color: #577084;
  transition: color 0.2s ease-out;
  cursor: pointer;
  padding-inline: 15px;
}

.form__input label ~ .pass-switch {
  top: 23px;
}

input[type=text],
input[type=password],
input[type=email],
input[type=tel],
input[type=number],
input[type=search],
input[type=file],
textarea {
  width: 100%;
  height: 50px;
  padding: 8px 16px;
  font-size: 20px;
  font-weight: 300;
  line-height: 20px;
  color: #444444;
  background: #FFFFFF;
  border: 1px solid transparent;
  border-radius: 10px;
  box-sizing: border-box;
  transition: all 0.4s;
  flex-shrink: 0;
  text-overflow: ellipsis;
  font-family: "Montserrat", sans-serif;
}
input[type=text]::placeholder,
input[type=password]::placeholder,
input[type=email]::placeholder,
input[type=tel]::placeholder,
input[type=number]::placeholder,
input[type=search]::placeholder,
input[type=file]::placeholder,
textarea::placeholder {
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.4s;
  text-overflow: ellipsis;
}
input[type=text]:focus::placeholder,
input[type=password]:focus::placeholder,
input[type=email]:focus::placeholder,
input[type=tel]:focus::placeholder,
input[type=number]:focus::placeholder,
input[type=search]:focus::placeholder,
input[type=file]:focus::placeholder,
textarea:focus::placeholder {
  opacity: 0;
}
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=tel]:focus,
input[type=number]:focus,
input[type=search]:focus,
input[type=file]:focus,
textarea:focus {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
}

textarea {
  width: 100%;
  max-width: 100%;
  height: 100px;
  padding: 16px;
  border-radius: 10px;
  line-height: 1.5;
  overflow: auto;
  box-sizing: border-box;
  resize: none;
  font-family: "Montserrat", sans-serif;
}

.form__input {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 500px;
}
.form__input:not(:last-child) {
  margin-bottom: 16px;
}
.form__input.short {
  width: 190px;
}
.form__input label {
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #7D7C7C;
}
.form__input.error label {
  color: #E6434F;
}
.form__input.error input {
  border-color: #E6434F;
}
.form__input--file input[type=file] {
  position: absolute;
  opacity: 0;
}
.form__input--file input[type=text] {
  pointer-events: none;
}
.form__input--date {
  position: relative;
}
.form__input--date i {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 26px;
  color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}
.form__input--increment {
  max-width: 100%;
}
.form__input--increment .button {
  position: absolute;
  bottom: 0;
  width: 50px;
  border-radius: 8px;
  color: #FFFFFF;
  padding: 0;
  font-size: 34px;
  font-weight: 600;
}
.form__input--increment .button.minus {
  left: 0;
  background: #7D7C7C;
}
.form__input--increment .button.plus {
  right: 0;
  background: #70E8A0;
}

.form__input input[type=text]:disabled,
.form__input input[type=password]:disabled,
.form__input input[type=email]:disabled,
.form__input input[type=tel]:disabled,
.form__input textarea:disabled {
  color: #9FA4BC;
  border-color: #9FA4BC;
  cursor: default;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.input__description {
  font-size: 14px;
  line-height: 25px;
  color: #666669;
  max-width: 500px;
}

.checkbox {
  text-align: left;
}
.checkbox input[type=checkbox] {
  display: none;
  height: 0;
  visibility: hidden;
}
.checkbox input[type=checkbox] + label {
  position: relative;
  display: inline-block;
  width: auto;
  padding-left: 26px;
  min-height: 18px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  color: #577084;
  cursor: pointer;
}
.checkbox input[type=checkbox] + label::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #7D7C7C;
  border-radius: 4px;
  transform: translateY(-2px);
  transition: 0.3s;
}
.checkbox input[type=checkbox] + label::after {
  content: "";
  position: absolute;
  top: 4px;
  left: 3px;
  width: 10px;
  height: 6px;
  border-bottom: 2px solid #FFFFFF;
  border-left: 2px solid #FFFFFF;
  transform: rotate(-45deg);
  opacity: 0;
  outline: none;
  transition: opacity 0.3s;
}
.checkbox input[type=checkbox]:checked + label::after {
  opacity: 1;
}
.checkbox input[type=checkbox]:checked + label::before {
  background: #374957;
  border-color: #374957;
}
.checkbox input[type=checkbox]:disabled + label {
  cursor: default;
}
.checkbox input[type=checkbox]:disabled + label:after {
  border-color: #fff;
}
.checkbox input[type=checkbox]:disabled + label:before {
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}
.checkbox a {
  margin: 0 2px;
  text-decoration-line: underline;
  color: #0755B1;
}
.checkbox a:hover {
  text-decoration-line: none;
}
.checkbox--circle {
  width: 100%;
  min-height: 54px;
  padding: 8px 16px;
  color: #260303;
  border: 1px solid #D6E0E8;
  border-radius: 12px;
}
.checkbox--circle input[type=checkbox] + label::before {
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: unset;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.checkbox--circle input[type=checkbox]:checked + label {
  background: #FFDE57;
}
.checkbox--circle input[type=checkbox]:checked + label::before {
  border-color: #FFFFFF;
  background: transparent;
}
.checkbox--circle input[type=checkbox]:checked + label::after {
  left: unset;
  right: 5px;
  top: calc(50% - 2px);
  transform: translateY(-50%) rotate(-45deg);
}
.checkbox--circle input[type=checkbox] + label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0;
  font-size: 16px;
  padding-right: 42px;
  line-height: 1.5;
  padding-left: 0;
  user-select: none;
}

.select-wrapper {
  display: flex;
  max-width: 360px;
  justify-content: flex-start;
}
.select .selectric {
  width: auto;
  border: none;
  border-radius: 10px;
  box-sizing: border-box;
  transition: 0.4s;
  background: #ffffff;
}
.select .selectric .icon-arrow-down:before, .select .selectric .icon-arrow-down:after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  right: 22px;
  top: 50%;
  height: 1px;
  width: 9px;
  border-radius: 2px;
  background: #260303;
  backface-visibility: hidden;
  transition-property: transform;
  transition-duration: 0.2s;
}
.select .selectric .icon-arrow-down:before {
  transform: rotate(45deg);
  right: 28px;
}
.select .selectric .icon-arrow-down:after {
  transform: rotate(-45deg);
}
.select .selectric-open .icon-arrow-down:before {
  transform: rotate(-45deg);
}
.select .selectric-open .icon-arrow-down:after {
  transform: rotate(45deg);
}
.select .selectric-wrapper {
  min-width: 100%;
}
.select .selectric-wrapper .label {
  width: 100%;
  height: 80px;
  margin: 0;
  color: #444444;
  font-weight: 300;
  line-height: 80px;
  padding: 0 40px 0 25px;
  font-size: 20px;
  text-align: left;
  transition: opacity 0.2s;
}
.select .selectric--placeholder .label {
  color: #7D7C7C;
}
.select .selectric-items {
  overflow: hidden;
}
.select .selectric-items li {
  font-weight: 300;
  font-size: 20px;
  line-height: 27px;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  transition: all 0.3s;
}
.select .selectric-disabled {
  opacity: 1;
}
.select .selectric-disabled .selectric .label {
  color: #9FA4BC;
}
.select .selectric-disabled .selectric__button:before, .select .selectric-disabled .selectric__button:after {
  background: #9FA4BC;
}

.radio input[type=radio] {
  position: absolute;
  opacity: 0;
}
.radio input[type=radio] + .radio-label {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  cursor: pointer;
  padding: 0 16px;
  transition: all 250ms ease;
}
.radio input[type=radio] + .radio-label span {
  font-weight: 600;
  font-size: 18px;
  color: #52503B;
}
.radio input[type=radio] + .radio-label a {
  font-weight: 500;
  font-size: 12px;
  text-decoration-line: underline;
  color: #52503B;
  margin-top: 12px;
}
.radio input[type=radio] + .radio-label a:hover {
  text-decoration-line: unset;
}
.radio input[type=radio] + .radio-label:before {
  content: "";
  border-radius: 100%;
  border: 2px solid #7D7C7C;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  transition: all 250ms ease;
}
.radio input[type=radio]:checked + .radio-label {
  background: #FFDE57;
}
.radio input[type=radio]:checked + .radio-label:before {
  background: #FFFFFF;
  box-shadow: inset 0 0 0 4px #FFDE57;
  border: 2px solid #FFFFFF;
}
.radio input[type=radio]:focus + .radio-label:before {
  outline: none;
  border-color: #FFFFFF;
}
.radio input[type=radio]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #FFDE57;
  border-color: #d7ac00;
  background: #d7ac00;
}
.radio input[type=radio] + .radio-label:empty:before {
  margin-right: 0;
}
.radio-label__text {
  display: flex;
  flex-direction: column;
}

.form__input--switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #EAEFF3;
}

.switch {
  display: inline-block;
  width: 44px;
  height: 24px;
  position: relative;
  background-color: #EFF3F6;
  border-radius: 12px;
  border: 1px solid #A7BAC9;
  appearance: none;
  user-select: none;
  cursor: pointer;
  transition: all 0.4s;
}

.switch::before {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 3px;
  top: 2px;
  background-color: #A7BAC9;
  border-radius: 50%;
  transition: all 0.4s;
}

.switch:checked {
  background-color: #E5FCE5;
  border-color: #59CA56;
}

.switch:checked::before {
  transform: translateX(20px);
  background-color: #59CA56;
}

.switch:disabled {
  background-color: hsl(0, 0%, 70%);
  cursor: default;
}

.switch:disabled::before {
  background-color: hsl(0, 0%, 90%);
}

.radio-switch {
  flex-direction: row;
}
.radio-switch label {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 50%;
  border: 1px solid #D6E0E8;
  cursor: pointer;
}
.radio-switch label:first-of-type {
  border-radius: 12px 0 0 12px;
}
.radio-switch label:last-of-type {
  border-radius: 0 12px 12px 0;
}
.radio-switch input {
  display: none;
  visibility: hidden;
}
.radio-switch input:checked + label {
  color: #FFFFFF;
  background: #59CA56;
  border-color: #59CA56;
}

.form-group {
  width: 100%;
  margin-bottom: 12px;
}

.page {
  padding-top: 64px;
  padding-bottom: 50px;
}
.page .faq {
  margin-top: 0;
}
.page-main {
  padding: 0;
  border: none;
}
.page-rooms {
  padding: 0;
  border: none;
}
.page-room {
  border-top: 1px solid #9A9A9A;
  padding-top: 56px;
  padding-bottom: 250px;
  background-image: url(../img/backgrounds/castle.svg);
  background-repeat: no-repeat;
  background-position: bottom right 140px;
}
.page-activities {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}
.page-form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 100%;
  padding-bottom: 60px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.page-form .logo {
  margin-top: 102px;
  margin-bottom: 40px;
  font-size: 64px;
  line-height: 42px;
  color: #FFFFFF;
}
.page-error {
  border: none;
  padding: 0 0 60px 0;
}
.page-error__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1062px;
  margin: auto;
  text-align: center;
  background-color: #F7F7F7;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  padding: 80px 40px;
  border-radius: 20px;
}
.page-error__content img {
  width: 200px;
  margin-bottom: 30px;
}
.page-error__title {
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
}
.page-error__description {
  margin-bottom: 32px;
  font-size: 18px;
}
.page__title {
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 20px;
  color: #52503B;
  text-align: center;
}
.page__subtitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #52503B;
  text-align: center;
}
.page .notification {
  text-align: center;
  margin: auto;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
          * Remove all paddings around the image on small screen
          */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 1px solid #DDD;
  border-radius: 0px;
  background: #F8F8F8;
  position: relative;
  overflow: hidden;
}
.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 38px 0 10px;
  font-size: 12px;
  line-height: 38px;
  color: #444;
  height: 38px;
  user-select: none;
}
.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  line-height: 38px;
  background-color: #F8f8f8;
  color: #BBB;
  text-align: center;
  font: 0/0 a;
  *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial;
}
.selectric .button:after {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: #BBB;
  border-bottom: none;
}

.selectric-focus .selectric {
  border-color: #aaaaaa;
}

.selectric-hover .selectric {
  border-color: #c4c4c4;
}
.selectric-hover .selectric .button {
  color: #a2a2a2;
}
.selectric-hover .selectric .button:after {
  border-top-color: #a2a2a2;
}

.selectric-open {
  z-index: 9999;
}
.selectric-open .selectric {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
}
.selectric-open .selectric-items {
  display: block;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}
.selectric-hide-select select {
  position: absolute;
  left: -100%;
}
.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: -1;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 10px 10px;
}
.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}
.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}
.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}
.selectric-items li {
  display: block;
  padding: 10px;
  color: #666;
  cursor: pointer;
}
.selectric-items li.selected {
  background: #EBFFEB;
  color: #444;
}
.selectric-items li.highlighted {
  background: #EBFFEB;
  color: #444;
}
.selectric-items li:hover {
  background: rgba(235, 255, 235, 0.5);
  color: #444;
}
.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  user-select: none;
}
.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  user-select: none;
  background: none;
  color: #444;
}
.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}
.selectric-items .selectric-group li {
  padding-left: 25px;
}

.air-datepicker-cell.-year-.-other-decade-, .air-datepicker-cell.-day-.-other-month- {
  color: var(--adp-color-other-month);
}
.air-datepicker-cell.-year-.-other-decade-:hover, .air-datepicker-cell.-day-.-other-month-:hover {
  color: var(--adp-color-other-month-hover);
}
.-disabled-.-focus-.air-datepicker-cell.-year-.-other-decade-, .-disabled-.-focus-.air-datepicker-cell.-day-.-other-month- {
  color: var(--adp-color-other-month);
}
.-selected-.air-datepicker-cell.-year-.-other-decade-, .-selected-.air-datepicker-cell.-day-.-other-month- {
  color: #fff;
  background: var(--adp-background-color-selected-other-month);
}
.-selected-.-focus-.air-datepicker-cell.-year-.-other-decade-, .-selected-.-focus-.air-datepicker-cell.-day-.-other-month- {
  background: var(--adp-background-color-selected-other-month-focused);
}
.-in-range-.air-datepicker-cell.-year-.-other-decade-, .-in-range-.air-datepicker-cell.-day-.-other-month- {
  background-color: var(--adp-background-color-in-range);
  color: var(--adp-color);
}
.-in-range-.-focus-.air-datepicker-cell.-year-.-other-decade-, .-in-range-.-focus-.air-datepicker-cell.-day-.-other-month- {
  background-color: var(--adp-background-color-in-range-focused);
}
.air-datepicker-cell.-year-.-other-decade-:empty, .air-datepicker-cell.-day-.-other-month-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Datepicker
   ------------------------------------------------- */
.air-datepicker {
  background: var(--adp-background-color);
  border: 1px solid var(--adp-border-color);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: var(--adp-border-radius);
  box-sizing: content-box;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, max-content);
  grid-template-areas: var(--adp-grid-areas);
  font-family: var(--adp-font-family), sans-serif;
  font-size: var(--adp-font-size);
  color: var(--adp-color);
  width: var(--adp-width);
  position: absolute;
  transition: opacity var(--adp-transition-duration) var(--adp-transition-ease), transform var(--adp-transition-duration) var(--adp-transition-ease);
  z-index: var(--adp-z-index);
}
.air-datepicker:not(.-custom-position-) {
  opacity: 0;
}
.air-datepicker.-from-top- {
  transform: translateY(calc(var(--adp-transition-offset) * -1));
}
.air-datepicker.-from-right- {
  transform: translateX(var(--adp-transition-offset));
}
.air-datepicker.-from-bottom- {
  transform: translateY(var(--adp-transition-offset));
}
.air-datepicker.-from-left- {
  transform: translateX(calc(var(--adp-transition-offset) * -1));
}
.air-datepicker.-active-:not(.-custom-position-) {
  transform: translate(0, 0);
  opacity: 1;
}
.air-datepicker.-active-.-custom-position- {
  transition: none;
}
.air-datepicker.-inline- {
  border-color: var(--adp-border-color-inline);
  box-shadow: none;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  transform: none;
}
.air-datepicker.-inline- .air-datepicker--pointer {
  display: none;
}
.air-datepicker.-is-mobile- {
  --adp-font-size: var(--adp-mobile-font-size);
  --adp-day-cell-height: var(--adp-mobile-day-cell-height);
  --adp-month-cell-height: var(--adp-mobile-month-cell-height);
  --adp-year-cell-height: var(--adp-mobile-year-cell-height);
  --adp-nav-height: var(--adp-mobile-nav-height);
  --adp-nav-action-size: var(--adp-mobile-nav-height);
  position: fixed;
  width: var(--adp-mobile-width);
  border: none;
}
.air-datepicker.-is-mobile- * {
  -webkit-tap-highlight-color: transparent;
}
.air-datepicker.-is-mobile- .air-datepicker--pointer {
  display: none;
}
.air-datepicker.-is-mobile-:not(.-custom-position-) {
  transform: translate(-50%, calc(-50% + var(--adp-transition-offset)));
}
.air-datepicker.-is-mobile-.-active-:not(.-custom-position-) {
  transform: translate(-50%, -50%);
}
.air-datepicker.-custom-position- {
  transition: none;
}

.air-datepicker-global-container {
  position: absolute;
  left: 0;
  top: 0;
}

.air-datepicker--pointer {
  --pointer-half-size: calc(var(--adp-pointer-size) / 2);
  position: absolute;
  width: var(--adp-pointer-size);
  height: var(--adp-pointer-size);
  z-index: -1;
}
.air-datepicker--pointer:after {
  content: "";
  position: absolute;
  background: #fff;
  border-top: 1px solid var(--adp-border-color-inline);
  border-right: 1px solid var(--adp-border-color-inline);
  border-top-right-radius: var(--adp-poiner-border-radius);
  width: var(--adp-pointer-size);
  height: var(--adp-pointer-size);
  box-sizing: border-box;
}
.-top-left- .air-datepicker--pointer, .-top-center- .air-datepicker--pointer, .-top-right- .air-datepicker--pointer, [data-popper-placement^=top] .air-datepicker--pointer {
  top: calc(100% - var(--pointer-half-size) + 1px);
}
.-top-left- .air-datepicker--pointer:after, .-top-center- .air-datepicker--pointer:after, .-top-right- .air-datepicker--pointer:after, [data-popper-placement^=top] .air-datepicker--pointer:after {
  transform: rotate(135deg);
}
.-right-top- .air-datepicker--pointer, .-right-center- .air-datepicker--pointer, .-right-bottom- .air-datepicker--pointer, [data-popper-placement^=right] .air-datepicker--pointer {
  right: calc(100% - var(--pointer-half-size) + 1px);
}
.-right-top- .air-datepicker--pointer:after, .-right-center- .air-datepicker--pointer:after, .-right-bottom- .air-datepicker--pointer:after, [data-popper-placement^=right] .air-datepicker--pointer:after {
  transform: rotate(225deg);
}
.-bottom-left- .air-datepicker--pointer, .-bottom-center- .air-datepicker--pointer, .-bottom-right- .air-datepicker--pointer, [data-popper-placement^=bottom] .air-datepicker--pointer {
  bottom: calc(100% - var(--pointer-half-size) + 1px);
}
.-bottom-left- .air-datepicker--pointer:after, .-bottom-center- .air-datepicker--pointer:after, .-bottom-right- .air-datepicker--pointer:after, [data-popper-placement^=bottom] .air-datepicker--pointer:after {
  transform: rotate(315deg);
}
.-left-top- .air-datepicker--pointer, .-left-center- .air-datepicker--pointer, .-left-bottom- .air-datepicker--pointer, [data-popper-placement^=left] .air-datepicker--pointer {
  left: calc(100% - var(--pointer-half-size) + 1px);
}
.-left-top- .air-datepicker--pointer:after, .-left-center- .air-datepicker--pointer:after, .-left-bottom- .air-datepicker--pointer:after, [data-popper-placement^=left] .air-datepicker--pointer:after {
  transform: rotate(45deg);
}
.-top-left- .air-datepicker--pointer, .-bottom-left- .air-datepicker--pointer {
  left: var(--adp-pointer-offset);
}
.-top-right- .air-datepicker--pointer, .-bottom-right- .air-datepicker--pointer {
  right: var(--adp-pointer-offset);
}
.-top-center- .air-datepicker--pointer, .-bottom-center- .air-datepicker--pointer {
  left: calc(50% - var(--adp-pointer-size) / 2);
}
.-left-top- .air-datepicker--pointer, .-right-top- .air-datepicker--pointer {
  top: var(--adp-pointer-offset);
}
.-left-bottom- .air-datepicker--pointer, .-right-bottom- .air-datepicker--pointer {
  bottom: var(--adp-pointer-offset);
}
.-left-center- .air-datepicker--pointer, .-right-center- .air-datepicker--pointer {
  top: calc(50% - var(--adp-pointer-size) / 2);
}

.air-datepicker--navigation {
  grid-area: nav;
}

.air-datepicker--content {
  box-sizing: content-box;
  padding: var(--adp-padding);
  grid-area: body;
}
.-only-timepicker- .air-datepicker--content {
  display: none;
}

.air-datepicker--time {
  grid-area: timepicker;
}

.air-datepicker--buttons {
  grid-area: buttons;
}

.air-datepicker--buttons,
.air-datepicker--time {
  padding: var(--adp-padding);
  border-top: 1px solid var(--adp-border-color-inner);
}

/*  Overlay
   ------------------------------------------------- */
.air-datepicker-overlay {
  position: fixed;
  background: var(--adp-overlay-background-color);
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
  transition: opacity var(--adp-overlay-transition-duration) var(--adp-overlay-transition-ease), left 0s, height 0s, width 0s;
  transition-delay: 0s, var(--adp-overlay-transition-duration), var(--adp-overlay-transition-duration), var(--adp-overlay-transition-duration);
  z-index: var(--adp-overlay-z-index);
}
.air-datepicker-overlay.-active- {
  opacity: 1;
  width: 100%;
  height: 100%;
  transition: opacity var(--adp-overlay-transition-duration) var(--adp-overlay-transition-ease), height 0s, width 0s;
}

/* -------------------------------------------------
    Datepicker body
   ------------------------------------------------- */
.air-datepicker-body {
  transition: all var(--adp-transition-duration) var(--adp-transition-ease);
}
.air-datepicker-body.-hidden- {
  display: none;
}

/*  Day names
   ------------------------------------------------- */
.air-datepicker-body--day-names {
  display: grid;
  grid-template-columns: repeat(7, var(--adp-day-cell-width));
  margin: 8px 0 3px;
}

.air-datepicker-body--day-name {
  color: var(--adp-day-name-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}
.air-datepicker-body--day-name.-clickable- {
  cursor: pointer;
}
.air-datepicker-body--day-name.-clickable-:hover {
  color: var(--adp-day-name-color-hover);
}

/*  Cells container
   ------------------------------------------------- */
.air-datepicker-body--cells {
  display: grid;
}
.air-datepicker-body--cells.-days- {
  grid-template-columns: repeat(7, var(--adp-day-cell-width));
  grid-auto-rows: var(--adp-day-cell-height);
}
.air-datepicker-body--cells.-months- {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: var(--adp-month-cell-height);
}
.air-datepicker-body--cells.-years- {
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: var(--adp-year-cell-height);
}

/* -------------------------------------------------
    Datepicker nav
   ------------------------------------------------- */
.air-datepicker-nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--adp-border-color-inner);
  min-height: var(--adp-nav-height);
  padding: var(--adp-padding);
  box-sizing: content-box;
}
.-only-timepicker- .air-datepicker-nav {
  display: none;
}

.air-datepicker-nav--title,
.air-datepicker-nav--action {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

/*  Navigation action
   ------------------------------------------------- */
.air-datepicker-nav--action {
  width: var(--adp-nav-action-size);
  border-radius: var(--adp-border-radius);
  user-select: none;
}
.air-datepicker-nav--action:hover {
  background: var(--adp-background-color-hover);
}
.air-datepicker-nav--action:active {
  background: var(--adp-background-color-active);
}
.air-datepicker-nav--action.-disabled- {
  visibility: hidden;
}
.air-datepicker-nav--action svg {
  width: 32px;
  height: 32px;
}
.air-datepicker-nav--action path {
  fill: none;
  stroke: var(--adp-nav-arrow-color);
  stroke-width: 2px;
}

/*  Navigation title
   ------------------------------------------------- */
.air-datepicker-nav--title {
  border-radius: var(--adp-border-radius);
  padding: 0 8px;
}
.air-datepicker-nav--title i {
  font-style: normal;
  color: var(--adp-nav-color-secondary);
  margin-left: 0.3em;
}
.air-datepicker-nav--title:hover {
  background: var(--adp-background-color-hover);
}
.air-datepicker-nav--title:active {
  background: var(--adp-background-color-active);
}
.air-datepicker-nav--title.-disabled- {
  cursor: default;
  background: none;
}

.air-datepicker-cell.-year-.-other-decade-, .air-datepicker-cell.-day-.-other-month- {
  color: var(--adp-color-other-month);
}
.air-datepicker-cell.-year-.-other-decade-:hover, .air-datepicker-cell.-day-.-other-month-:hover {
  color: var(--adp-color-other-month-hover);
}
.-disabled-.-focus-.air-datepicker-cell.-year-.-other-decade-, .-disabled-.-focus-.air-datepicker-cell.-day-.-other-month- {
  color: var(--adp-color-other-month);
}
.-selected-.air-datepicker-cell.-year-.-other-decade-, .-selected-.air-datepicker-cell.-day-.-other-month- {
  color: #fff;
  background: var(--adp-background-color-selected-other-month);
}
.-selected-.-focus-.air-datepicker-cell.-year-.-other-decade-, .-selected-.-focus-.air-datepicker-cell.-day-.-other-month- {
  background: var(--adp-background-color-selected-other-month-focused);
}
.-in-range-.air-datepicker-cell.-year-.-other-decade-, .-in-range-.air-datepicker-cell.-day-.-other-month- {
  background-color: var(--adp-background-color-in-range);
  color: var(--adp-color);
}
.-in-range-.-focus-.air-datepicker-cell.-year-.-other-decade-, .-in-range-.-focus-.air-datepicker-cell.-day-.-other-month- {
  background-color: var(--adp-background-color-in-range-focused);
}
.air-datepicker-cell.-year-.-other-decade-:empty, .air-datepicker-cell.-day-.-other-month-:empty {
  background: none;
  border: none;
}

/* -------------------------------------------------
    Datepicker cell
   ------------------------------------------------- */
.air-datepicker-cell {
  border-radius: var(--adp-cell-border-radius);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.air-datepicker-cell.-focus- {
  background: var(--adp-cell-background-color-hover);
}
.air-datepicker-cell.-current- {
  color: var(--adp-color-current-date);
}
.air-datepicker-cell.-current-.-focus- {
  color: var(--adp-color);
}
.air-datepicker-cell.-current-.-in-range- {
  color: var(--adp-color-current-date);
}
.air-datepicker-cell.-disabled- {
  cursor: default;
  color: var(--adp-color-disabled);
}
.air-datepicker-cell.-disabled-.-focus- {
  color: var(--adp-color-disabled);
}
.air-datepicker-cell.-disabled-.-in-range- {
  color: var(--adp-color-disabled-in-range);
}
.air-datepicker-cell.-disabled-.-current-.-focus- {
  color: var(--adp-color-disabled);
}
.air-datepicker-cell.-in-range- {
  background: var(--adp-cell-background-color-in-range);
  border-radius: 0;
}
.air-datepicker-cell.-in-range-:hover {
  background: var(--adp-cell-background-color-in-range-hover);
}
.air-datepicker-cell.-range-from- {
  border: 1px solid var(--adp-cell-border-color-in-range);
  background-color: var(--adp-cell-background-color-in-range);
  border-radius: var(--adp-cell-border-radius) 0 0 var(--adp-cell-border-radius);
}
.air-datepicker-cell.-range-to- {
  border: 1px solid var(--adp-cell-border-color-in-range);
  background-color: var(--adp-cell-background-color-in-range);
  border-radius: 0 var(--adp-cell-border-radius) var(--adp-cell-border-radius) 0;
}
.air-datepicker-cell.-range-to-.-range-from- {
  border-radius: var(--adp-cell-border-radius);
}
.air-datepicker-cell.-selected- {
  color: #fff;
  border: none;
  background: var(--adp-cell-background-color-selected);
}
.air-datepicker-cell.-selected-.-current- {
  color: #fff;
  background: var(--adp-cell-background-color-selected);
}
.air-datepicker-cell.-selected-.-focus- {
  background: var(--adp-cell-background-color-selected-hover);
}

/*  Day cell
   ------------------------------------------------- */
/*  Year cell
   ------------------------------------------------- */
/* -------------------------------------------------
    Datepicker buttons
   ------------------------------------------------- */
.air-datepicker-buttons {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
}

.air-datepicker-button {
  display: inline-flex;
  color: var(--adp-btn-color);
  border-radius: var(--adp-btn-border-radius);
  cursor: pointer;
  height: var(--adp-btn-height);
  border: none;
  background: rgba(255, 255, 255, 0);
}
.air-datepicker-button:hover {
  color: var(--adp-btn-color-hover);
  background: var(--adp-btn-background-color-hover);
}
.air-datepicker-button:focus {
  color: var(--adp-btn-color-hover);
  background: var(--adp-btn-background-color-hover);
  outline: none;
}
.air-datepicker-button:active {
  background: var(--adp-btn-background-color-active);
}
.air-datepicker-button span {
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* -------------------------------------------------
    Datepicker vars
   ------------------------------------------------- */
.air-datepicker {
  --adp-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --adp-font-size: 14px;
  --adp-width: 246px;
  --adp-z-index: 100;
  --adp-padding: 4px;
  --adp-grid-areas:
    "nav"
    "body"
    "timepicker"
    "buttons";
  --adp-transition-duration: .3s;
  --adp-transition-ease: ease-out;
  --adp-transition-offset: 8px;
  --adp-background-color: #fff;
  --adp-background-color-hover: #f0f0f0;
  --adp-background-color-active: #eaeaea;
  --adp-background-color-in-range: rgba(92, 196, 239, .1);
  --adp-background-color-in-range-focused: rgba(92, 196, 239, .2);
  --adp-background-color-selected-other-month-focused: #8ad5f4;
  --adp-background-color-selected-other-month: #a2ddf6;
  --adp-color: #4a4a4a;
  --adp-color-secondary: #9c9c9c;
  --adp-accent-color: #4eb5e6;
  --adp-color-current-date: var(--adp-accent-color);
  --adp-color-other-month: #dedede;
  --adp-color-disabled: #aeaeae;
  --adp-color-disabled-in-range: #939393;
  --adp-color-other-month-hover: #c5c5c5;
  --adp-border-color: #dbdbdb;
  --adp-border-color-inner: #efefef;
  --adp-border-radius: 4px;
  --adp-border-color-inline: #d7d7d7;
  --adp-nav-height: 32px;
  --adp-nav-arrow-color: var(--adp-color-secondary);
  --adp-nav-action-size: 32px;
  --adp-nav-color-secondary: var(--adp-color-secondary);
  --adp-day-name-color: #ff9a19;
  --adp-day-name-color-hover: #8ad5f4;
  --adp-day-cell-width: 1fr;
  --adp-day-cell-height: 32px;
  --adp-month-cell-height: 42px;
  --adp-year-cell-height: 56px;
  --adp-pointer-size: 10px;
  --adp-poiner-border-radius: 2px;
  --adp-pointer-offset: 14px;
  --adp-cell-border-radius: 4px;
  --adp-cell-background-color-hover: var(--adp-background-color-hover);
  --adp-cell-background-color-selected: #5cc4ef;
  --adp-cell-background-color-selected-hover: #45bced;
  --adp-cell-background-color-in-range: rgba(92, 196, 239, 0.1);
  --adp-cell-background-color-in-range-hover: rgba(92, 196, 239, 0.2);
  --adp-cell-border-color-in-range: var(--adp-cell-background-color-selected);
  --adp-btn-height: 32px;
  --adp-btn-color: var(--adp-accent-color);
  --adp-btn-color-hover: var(--adp-color);
  --adp-btn-border-radius: var(--adp-border-radius);
  --adp-btn-background-color-hover: var(--adp-background-color-hover);
  --adp-btn-background-color-active: var(--adp-background-color-active);
  --adp-time-track-height: 1px;
  --adp-time-track-color: #dedede;
  --adp-time-track-color-hover: #b1b1b1;
  --adp-time-thumb-size: 12px;
  --adp-time-padding-inner: 10px;
  --adp-time-day-period-color: var(--adp-color-secondary);
  --adp-mobile-font-size: 16px;
  --adp-mobile-nav-height: 40px;
  --adp-mobile-width: 320px;
  --adp-mobile-day-cell-height: 38px;
  --adp-mobile-month-cell-height: 48px;
  --adp-mobile-year-cell-height: 64px;
}

.air-datepicker-overlay {
  --adp-overlay-background-color: rgba(0, 0, 0, .3);
  --adp-overlay-transition-duration: .3s;
  --adp-overlay-transition-ease: ease-out;
  --adp-overlay-z-index: 99;
}

/* -------------------------------------------------
    Datepicker buttons
   ------------------------------------------------- */
.air-datepicker-buttons {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
}

.air-datepicker-button {
  display: inline-flex;
  color: var(--adp-btn-color);
  border-radius: var(--adp-btn-border-radius);
  cursor: pointer;
  height: var(--adp-btn-height);
  border: none;
  background: rgba(255, 255, 255, 0);
}
.air-datepicker-button:hover {
  color: var(--adp-btn-color-hover);
  background: var(--adp-btn-background-color-hover);
}
.air-datepicker-button:focus {
  color: var(--adp-btn-color-hover);
  background: var(--adp-btn-background-color-hover);
  outline: none;
}
.air-datepicker-button:active {
  background: var(--adp-btn-background-color-active);
}
.air-datepicker-button span {
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* -------------------------------------------------
    Timepicker
   ------------------------------------------------- */
.air-datepicker-time {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 12px;
  align-items: center;
  position: relative;
  padding: 0 var(--adp-time-padding-inner);
}
.-only-timepicker- .air-datepicker-time {
  border-top: none;
}

.air-datepicker-time--current {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 14px;
  text-align: center;
}

.air-datepicker-time--current-colon {
  margin: 0 2px 3px;
  line-height: 1;
}

.air-datepicker-time--current-hours,
.air-datepicker-time--current-minutes {
  line-height: 1;
  font-size: 19px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1;
}
.air-datepicker-time--current-hours:after,
.air-datepicker-time--current-minutes:after {
  content: "";
  background: var(--adp-background-color-hover);
  border-radius: var(--adp-border-radius);
  position: absolute;
  left: -2px;
  top: -3px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  opacity: 0;
}
.air-datepicker-time--current-hours.-focus-:after,
.air-datepicker-time--current-minutes.-focus-:after {
  opacity: 1;
}

.air-datepicker-time--current-ampm {
  text-transform: uppercase;
  align-self: flex-end;
  color: var(--adp-time-day-period-color);
  margin-left: 6px;
  font-size: 11px;
  margin-bottom: 1px;
}

.air-datepicker-time--row {
  display: flex;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background: linear-gradient(to right, var(--adp-time-track-color), var(--adp-time-track-color)) left 50%/100% var(--adp-time-track-height) no-repeat;
}
.air-datepicker-time--row:first-child {
  margin-bottom: 4px;
}
.air-datepicker-time--row input[type=range] {
  background: none;
  cursor: pointer;
  flex: 1;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}
.air-datepicker-time--row input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.air-datepicker-time--row input[type=range]::-ms-tooltip {
  display: none;
}
.air-datepicker-time--row input[type=range]:hover::-webkit-slider-thumb {
  border-color: var(--adp-time-track-color-hover);
}
.air-datepicker-time--row input[type=range]:hover::-moz-range-thumb {
  border-color: var(--adp-time-track-color-hover);
}
.air-datepicker-time--row input[type=range]:hover::-ms-thumb {
  border-color: var(--adp-time-track-color-hover);
}
.air-datepicker-time--row input[type=range]:focus {
  outline: none;
}
.air-datepicker-time--row input[type=range]:focus::-webkit-slider-thumb {
  background: var(--adp-cell-background-color-selected);
  border-color: var(--adp-cell-background-color-selected);
}
.air-datepicker-time--row input[type=range]:focus::-moz-range-thumb {
  background: var(--adp-cell-background-color-selected);
  border-color: var(--adp-cell-background-color-selected);
}
.air-datepicker-time--row input[type=range]:focus::-ms-thumb {
  background: var(--adp-cell-background-color-selected);
  border-color: var(--adp-cell-background-color-selected);
}
.air-datepicker-time--row input[type=range]::-webkit-slider-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid var(--adp-time-track-color);
  background: #fff;
  cursor: pointer;
  transition: background var(--adp-transition-duration);
}
.air-datepicker-time--row input[type=range]::-moz-range-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid var(--adp-time-track-color);
  background: #fff;
  cursor: pointer;
  transition: background var(--adp-transition-duration);
}
.air-datepicker-time--row input[type=range]::-ms-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid var(--adp-time-track-color);
  background: #fff;
  cursor: pointer;
  transition: background var(--adp-transition-duration);
}
.air-datepicker-time--row input[type=range]::-webkit-slider-thumb {
  margin-top: calc(var(--adp-time-thumb-size) / 2 * -1);
}
.air-datepicker-time--row input[type=range]::-webkit-slider-runnable-track {
  border: none;
  height: var(--adp-time-track-height);
  cursor: pointer;
  color: transparent;
  background: transparent;
}
.air-datepicker-time--row input[type=range]::-moz-range-track {
  border: none;
  height: var(--adp-time-track-height);
  cursor: pointer;
  color: transparent;
  background: transparent;
}
.air-datepicker-time--row input[type=range]::-ms-track {
  border: none;
  height: var(--adp-time-track-height);
  cursor: pointer;
  color: transparent;
  background: transparent;
}
.air-datepicker-time--row input[type=range]::-ms-fill-lower {
  background: transparent;
}
.air-datepicker-time--row input[type=range]::-ms-fill-upper {
  background: transparent;
}
@media all and (max-width: 1440px) {
  .services-list {
    padding: 0;
  }
  .room-booking {
    display: flex;
    flex-direction: column-reverse;
    max-height: unset;
  }
  .contacts__wrapper {
    grid-template-columns: 1fr 2fr;
  }
}
@media all and (max-width: 1280px) {
  .logo a img {
    height: 80px;
  }
  .container {
    padding: 0 20px;
  }
  .header-layout {
    height: 120px;
  }
  .header-row:first-child, .header-row:last-child {
    align-items: center;
    padding: 0;
  }
  .section-discount h4 {
    font-size: 24px;
  }
  .services-list {
    grid-template-columns: repeat(4, 1fr);
  }
  .form--availability {
    grid-template-columns: 1fr;
    max-width: 420px;
  }
  .swiper-half .swiper-slide .swiper-half-l {
    padding: 20px 40px;
  }
  .swiper-pagination.long .swiper-pagination-bullet {
    width: 50px;
  }
  .faq__item {
    padding: 0 32px;
  }
  .rooms__list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 35px;
  }
  .booking__wrapper {
    grid-gap: 40px;
  }
  .contacts__wrapper {
    display: flex;
    flex-direction: column;
  }
  .contacts__address {
    width: 100%;
    flex-direction: unset;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .long-stay-amenities__wrapper {
    grid-template-columns: 1fr;
  }
}
@media all and (max-width: 1024px) {
  .header-row:first-child {
    justify-content: flex-start;
  }
  .header-row:last-child {
    justify-content: flex-end;
  }
  .burger-menu__opener {
    display: flex;
  }
  .header-menu, .header__contacts {
    display: none;
  }
  .section-discount h4 {
    font-size: 18px;
  }
  .section__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
  .room-wrapper, .activities-item__wrapper {
    display: flex;
    flex-direction: column;
  }
  .room-booking {
    grid-template-columns: 1fr 1fr;
  }
  .long-stay-item__wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }
}
@media all and (max-width: 980px) {
  .helper {
    margin-top: 70px;
  }
  .section-main {
    height: calc(100vh - 130px);
  }
  .section-main__title {
    width: 100%;
  }
  .section-main__title h2 {
    font-size: 24px;
  }
  .section-main__title .lime {
    width: 120px;
  }
  .header-layout {
    height: 70px;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 10px;
  }
  .header-layout .lang {
    display: none;
  }
  .header-row .button {
    padding: 5px;
    font-weight: 600;
    letter-spacing: unset;
    height: 40px;
    font-size: 12px;
  }
  .logo a img {
    height: 60px;
  }
  .section-split {
    height: calc(100vh - 70px);
  }
  .split-content__title, .split-content--right .split-content__title {
    top: 60px;
    left: 50%;
    right: auto;
    width: 100%;
    padding: 0 15px;
    transform: translateX(-50%);
    text-align: center;
  }
  .split-content__title img, .split-content--right .split-content__title img {
    height: 50px;
  }
  .split-content .lime {
    top: 100%;
    left: 50%;
    width: 80px;
    height: 80px;
  }
  .split-content--right .lime {
    top: 0;
  }
  .split-content__button {
    bottom: 60px;
  }
  .split-content__button .button {
    height: 50px;
    min-width: unset;
    font-size: 14px;
    color: #444444;
    font-weight: 500;
  }
  .section-discount {
    padding: 15px;
  }
  .section-discount h4 {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
  }
  .section-booking {
    padding: 35px 32px 180px 32px;
    background-image: url(../img/backgrounds/lighthouse-mob.svg);
    background-position: bottom center;
    background-size: 80%;
  }
  .button i {
    font-size: 12px;
  }
  .form--availability {
    margin: 40px auto 0 auto;
  }
  .form--availability .button--t1 {
    height: 50px;
    font-size: 14px;
    line-height: 17px;
    color: #444444;
  }
  .form--availability .form__input input {
    height: 50px;
    font-size: 14px;
  }
  .select .selectric-wrapper .label {
    height: 50px;
    font-size: 14px;
    line-height: 50px;
  }
  .select .selectric-items li {
    height: 50px;
    font-size: 14px;
  }
  .slider-features .swiper-slide {
    flex-direction: column;
  }
  .swiper-half.swiper-container {
    height: 100vh;
  }
  .slider-features .swiper-slide .swiper-half-l {
    background-image: url(../img/backgrounds/glass-mob.svg), url(../img/backgrounds/leaf.svg);
    background-repeat: no-repeat;
    background-position: right 20px bottom 20px, left 40px top 40px;
    background-size: 140px, auto;
  }
  .swiper-half .swiper-slide .swiper-half-l, .swiper-half .swiper-slide .swiper-half-r {
    align-items: center;
    width: 100%;
    height: 50%;
  }
  .swiper-half .swiper-slide .swiper-half-l img {
    width: auto;
    height: 50px;
  }
  .swiper-half__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0;
  }
  .swiper-half__description {
    margin-top: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
  .swiper-half__navigation {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 16px;
  }
  .swiper-pagination.long .swiper-pagination-bullet {
    width: 30px;
  }
  .slider-activity .swiper-slide:first-of-type .swiper-half-l {
    background-position: bottom 100px right;
  }
  .section-services {
    padding: 35px 0;
  }
  .services-list {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 15px;
  }
  .services-list i {
    width: 60px;
    height: 60px;
    font-size: 32px;
    margin-bottom: 12px;
  }
  .service-list__name {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 0;
  }
  .swiper-half__buttons .button {
    height: 50px;
    font-size: 16px;
  }
  .section-activity {
    padding-top: 0;
  }
  .section-activity .section__title, .section-activity .section__subtitle {
    display: none;
  }
  .section-activity .slider-activity {
    margin-top: 0;
  }
  .section-activity .slider-activity .swiper-slide {
    flex-direction: column;
  }
  .section-activity .swiper-half .swiper-slide .swiper-half-r i {
    top: auto;
    left: auto;
    right: 16px;
    bottom: 16px;
    width: 50px;
    height: 50px;
    font-size: 32px;
  }
  .section-activity .activity-menu {
    display: none;
  }
  .section__subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 0 16px;
  }
  .section-feedback {
    padding: 35px 0;
    background-position: top 100px left, bottom 80px right 100px;
    background-size: 80px, 50px;
  }
  .feedback-slider__wrapper {
    padding: 0 16px;
    margin-top: 50px;
  }
  .feedback-slider__wrapper .feedback__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .feedback-slider__wrapper .feedback__rating {
    font-size: 10px;
  }
  .feedback-slider__wrapper p {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #7D7C7C;
  }
  .swiper-pagination__wrapper {
    bottom: 16px;
    z-index: 1;
    grid-template-columns: 1fr;
  }
  .section-faq {
    padding-top: 35px;
  }
  .section-faq:after {
    right: 12px;
    bottom: 0;
    width: 200px;
    height: 190px;
    background-size: contain;
    background-position: bottom;
  }
  .faq {
    margin-top: 20px;
  }
  .faq__question {
    font-size: 12px;
    line-height: 18px;
  }
  .faq__question i {
    font-size: 6px;
  }
  .faq__answer {
    font-size: 11px;
    line-height: 14px;
  }
  .footer .copyright {
    font-size: 8px;
    line-height: 12px;
  }
  .footer-copyright .logo {
    margin-bottom: 8px;
  }
  .footer-layout {
    padding: 40px 0 12px 0;
    background-position: center bottom;
    background-size: 200px;
  }
  .footer-layout .footer-row {
    width: 100%;
    text-align: center;
  }
  .footer__title {
    font-weight: 600;
    color: #FFFFFF;
  }
  .footer-layout--top {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer-layout--top .form__section {
    width: 100%;
  }
  .footer-layout--top .socials {
    justify-content: center;
  }
  input[type=text], input[type=password], input[type=email], input[type=tel], input[type=search], input[type=file], textarea {
    font-size: 14px;
  }
  .form--feedback .button--t1 {
    font-size: 14px;
  }
  .form--feedback .form__section--submit {
    display: flex;
    flex-direction: column;
  }
  .rooms-header, .activities-header {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 70px);
    background-position: bottom 40px right;
    background-size: 150px;
  }
  .rooms-header .rooms-header__content, .rooms-header .activities-header__content, .activities-header .rooms-header__content, .activities-header .activities-header__content {
    padding: 40px;
    height: 50%;
  }
  .rooms-header .rooms-header__content h1, .rooms-header .activities-header__content h1, .activities-header .rooms-header__content h1, .activities-header .activities-header__content h1 {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 32px;
  }
  .rooms-header .rooms-header__content p, .rooms-header .activities-header__content p, .activities-header .rooms-header__content p, .activities-header .activities-header__content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0;
    text-align: center;
    justify-content: center;
  }
  .activities-header .activities-header__img {
    background-size: cover;
    height: 50%;
  }
  .activities-header .activities-header__img img {
    height: 100%;
  }
  .activities-header__content {
    text-align: center;
  }
  .activities-header__content .button {
    margin-top: 30px;
  }
  .activities-item {
    display: flex;
    flex-direction: column-reverse;
  }
  .activities-item:not(:last-child) {
    margin-bottom: 20px;
  }
  .section-select-rooms {
    padding: 35px 32px 180px 32px;
    background-image: url(../img/backgrounds/lighthouse-mob.svg);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 80%;
  }
  .section-rooms {
    padding: 35px 0;
  }
  .rooms__list {
    grid-template-columns: 1fr;
    grid-gap: 35px;
  }
  .rooms-item__highlights {
    font-size: 24px;
  }
  .rooms-item__buttons {
    margin-top: 20px;
  }
  .rooms-item__buttons .button {
    width: 100%;
  }
  .button--s2 {
    height: 50px;
  }
  .button--t1, .button--t4, .button--t3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
  }
  .rooms-item__preview {
    height: 240px;
  }
  .section-promo {
    padding: 35px 16px;
    justify-content: center;
    height: auto;
  }
  .section-promo h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }
  .section-promo .button--t2 {
    font-size: 14px;
    font-weight: 400;
  }
  .booking__checkin .booking__date, .booking__checkout .booking__date {
    font-size: 14px;
  }
  .booking__checkin span, .booking__checkout span {
    font-size: 12px;
  }
  .booking__buttons {
    grid-gap: 20px;
  }
  .section-activities {
    padding-top: 0;
  }
  .services-exception {
    padding: 0;
  }
}
@media all and (max-width: 768px) {
  .booking__wrapper {
    grid-template-columns: 1fr;
  }
  .booking__wrapper:before {
    content: none;
  }
  .booking-steps {
    margin-bottom: 30px;
  }
  .booking-steps:before {
    width: 200px;
  }
  .booking-steps .step {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .booking-steps .step.current:before {
    width: 42px;
    height: 42px;
  }
  .booking__details {
    font-size: 14px;
    font-weight: 400;
  }
  .booking__details li {
    padding: 10px 0;
  }
  .booking__details li:last-child {
    padding-top: 15px;
    margin-top: 10px;
  }
  .booking__total {
    padding: 20px;
  }
  .booking__type {
    font-size: 14px;
  }
  .form--guest-info .form__input label {
    font-size: 14px;
    margin-bottom: 8px;
  }
  .form--guest-info .form__input:not(:last-child) {
    margin-bottom: 20px;
  }
  .form--guest-info textarea {
    height: 140px;
  }
  .booking__buttons {
    margin-top: 30px;
    grid-gap: 20px;
  }
  .form--guest-info input[type=text], .form--guest-info input[type=password], .form--guest-info input[type=email], .form--guest-info input[type=tel], .form--guest-info input[type=number], .form--guest-info input[type=search], .form--guest-info input[type=file], .form--guest-info textarea {
    font-size: 14px;
  }
  .booking-notification__success h4 {
    font-size: 20px;
  }
  .booking-notification__description {
    font-size: 14px;
    line-height: 1.2;
  }
  .activities-item__photos {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
  }
  .activities__preview {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .activities__preview img {
    width: 100%;
  }
}
@media all and (max-width: 680px) {
  .lang {
    font-size: 16px;
    line-height: 18px;
  }
  .page-room {
    padding-top: 35px;
    padding-bottom: 100px;
    background-position: bottom right 20px;
    background-size: 120px;
  }
  .page__title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .breadcrumbs {
    font-size: 12px;
    margin-bottom: 16px;
  }
  .slider-room__wrapper {
    max-height: 280px;
    margin-bottom: 16px;
  }
  .slider-room-thumbs__wrapper {
    max-height: 120px;
    height: 100%;
    margin-bottom: 16px;
    padding: 8px;
  }
  .room-amenities {
    padding: 20px;
    margin-bottom: 16px;
  }
  .room-amenities__title, .room-rules__title {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .room-amenities__list {
    grid-gap: 10px;
  }
  .room-amenities__list li i {
    font-size: 24px;
    margin-right: 10px;
  }
  .room-amenities__list li span {
    font-size: 11px;
    line-height: 1;
  }
  .room-rules {
    padding: 20px;
  }
  .room-rules__time {
    margin-bottom: 10px;
    width: 100%;
    justify-content: space-between;
  }
  .room-rules__time .check__title {
    font-size: 12px;
    line-height: 1;
  }
  .room__price {
    padding: 8px;
    font-size: 10px;
    line-height: 1;
  }
  .room__price .price__volume {
    font-size: 14px;
    line-height: 1;
  }
  .room-rules__list {
    grid-template-columns: 1fr;
  }
  .booking-calendar {
    margin-bottom: 20px;
    padding: 20px;
  }
  .room-booking {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
  .room-description__title, .room-safety__title {
    font-size: 18px;
    line-height: 20px;
  }
  .room-description p:last-child {
    margin-bottom: 0;
  }
  .room-description, .room-safety__item {
    font-size: 14px;
  }
  .rooms-item__price {
    left: 10px;
    height: 40px;
    width: 100px;
  }
  .room-wrapper, .activities-item__wrapper {
    grid-gap: 20px;
  }
  .room-safety__list {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
  .form--booking {
    padding: 20px;
  }
  .form--booking .form__section--submit {
    margin-top: 20px;
  }
  .activities-item__photos {
    grid-gap: 10px;
  }
  .activities-item__title {
    font-size: 18px;
  }
  .activities-item__short-description p {
    font-size: 14px;
  }
  .activities-item__params {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  .activities-level {
    width: 100%;
    height: 40px;
  }
  .slider-activities-item__wrapper {
    margin-bottom: 10px;
  }
  .slider-activities-item-thumbs__wrapper {
    padding: 5px;
    margin-bottom: 10px;
  }
  .slider-activities-item-thumbs__wrapper .swiper .swiper-slide {
    border-radius: 5px;
  }
  .activities-item__characteristics .activities-param {
    margin-left: 0;
    height: 40px;
  }
  .activities-item__characteristics {
    padding: 20px;
  }
  .activities-item__characteristics h3 {
    font-size: 20px;
  }
  .activities-item__text h1 {
    font-size: 20px;
    line-height: 1.2;
  }
  .long-stay__packages {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .long-stay-amenities {
    padding: 20px;
  }
  .long-stay-amenities__list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  .long-stay-amenities__list span {
    font-size: 12px;
  }
  .long-stay-item__wrapper {
    padding-bottom: 30px;
  }
}
@media all and (min-width: 2200px) {
  body > * {
    zoom: 120%;
  }
}
@media all and (min-width: 2800px) {
  body > * {
    zoom: 150%;
  }
}
@media all and (min-width: 3840px) {
  body > * {
    zoom: 200%;
  }
}
.footer-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 60px 0 20px 0;
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url("../img/backgrounds/lime-gray.svg");
  background-repeat: no-repeat;
  background-position: left 100px bottom;
}
.footer-layout--top {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-gap: 40px;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 32px;
}

.footer {
  color: #FFFFFF;
}
.footer-row:last-child {
  margin-left: auto;
}
.footer-row .copyright {
  display: none;
}
.footer__title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.footer__subtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}
.footer-copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.footer-copyright .logo {
  margin-bottom: 32px;
}
.footer-copyright .logo a img {
  height: 30px;
}
.footer .copyright {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
}

.header {
  background-color: #FFFFFF;
  border-bottom: 1px solid #9A9A9A;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}
.header__contacts {
  color: #9A9A9A;
}
.header__contacts .contacts__phone {
  display: flex;
  align-items: center;
  transition: color 0.2s ease-out;
}
.header__contacts .contacts__phone:not(:last-child) {
  margin-bottom: 8px;
}
.header__contacts .contacts__phone:hover {
  color: #000000;
}
.header__contacts .contacts__phone i {
  font-size: 24px;
  margin-right: 24px;
}
.header__contacts .contacts__phone.eco i {
  color: #70E8A0;
}
.header__contacts .contacts__phone.regular i {
  color: #FFDE57;
}

.header-layout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  align-items: center;
  height: 100px;
}

.header-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 24px;
}
.header-row .button {
  width: fit-content;
  letter-spacing: unset;
  text-transform: uppercase;
}
.header-row:first-child {
  justify-content: flex-start;
  align-items: center;
}
.header-row:last-child {
  justify-content: flex-end;
  align-items: center;
}

.lang {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
}
.lang__item {
  transition: color 0.2s;
}
.lang__item:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  padding-right: 8px;
  margin-right: 8px;
}
.lang__item:hover {
  color: #000000;
}

.profile-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

/*
* Лоадер
* Используется при загрузке страницы
*/
.loader {
  background-color: rgb(70, 150, 155);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader img {
  width: 80px;
}
.loader .lds-ellipsis {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-top: -24px;
  margin-left: 10px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #FDC70D;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.loader-box {
  width: 24px;
  height: 24px;
  display: flex;
  margin: 0 auto 18px auto;
}

.loader-container {
  animation: rotate 1s infinite;
  height: 18px;
  width: 18px;
}

.loader-container:before,
.loader-container:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 8px;
  width: 8px;
}

.loader-container:before {
  animation: ball1 1s infinite;
  background-color: #1DAAF9;
  box-shadow: 16px 0 0 #FFCE0A;
  margin-bottom: 4px;
}

.loader-container:after {
  animation: ball2 1s infinite;
  background-color: #36D680;
  box-shadow: 16px 0 0 #E374FF;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  50% {
    transform: rotate(360deg) scale(1.2);
  }
  100% {
    transform: rotate(720deg) scale(0.8);
  }
}
@keyframes ball1 {
  0% {
    box-shadow: 12px 0 0 #E374FF;
  }
  50% {
    box-shadow: 0 0 0 #E374FF;
    margin-bottom: 0;
    transform: translate(4px, 4px);
  }
  100% {
    box-shadow: 12px 0 0 #E374FF;
    margin-bottom: 4px;
  }
}
@keyframes ball2 {
  0% {
    box-shadow: 12px 0 0 #FFCE0A;
  }
  50% {
    box-shadow: 0 0 0 #FFCE0A;
    margin-top: -8px;
    transform: translate(4px, 4px);
  }
  100% {
    box-shadow: 12px 0 0 #FFCE0A;
    margin-top: 0;
  }
}
.logo a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: unset;
}
.logo a img {
  height: 60px;
  object-fit: contain;
}

.section-split {
  position: relative;
  height: 890px;
}

.section-split__wrapper {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
  background-color: #000;
}
@media (min-width: 1024px) {
  .section-split__wrapper {
    display: flex;
  }
}

.split-content {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
}
@media (min-width: 1024px) {
  .split-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    transition: all 1.1s cubic-bezier(0.8, 0, 0.2, 1);
  }
}
@media (min-width: 1024px) {
  .split-content:hover {
    flex: 3;
  }
}
.split-content:hover .split-content__image img {
  transform: scale(1);
}
.split-content .lime {
  position: absolute;
  top: 50%;
  left: 50vw;
  z-index: 2;
  width: 200px;
  transform: translate(-50%, -50%);
  height: 200px;
}
.split-content--right .lime {
  right: calc(50vw - 200px);
  left: unset;
}
.split-content--right .split-content__title {
  right: 110px;
  left: auto;
}
.split-content--right .button--t3 {
  flex-direction: row-reverse;
}
.split-content--right .button--t3 i {
  margin-right: 0;
  margin-left: 12px;
}
.split-content__image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.split-content__image img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  transform: scale(1.2);
  transition: all 1s cubic-bezier(0.8, 0, 0.2, 1);
}
@supports not (object-fit: cover) {
  .split-content__image img {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
  }
}
.split-content__title {
  position: absolute;
  top: 35px;
  left: 110px;
  text-align: center;
}
.split-content__title h2 {
  padding: 0;
  margin-top: 24px;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
}
.split-content__title img {
  height: 120px;
}
.split-content__button {
  position: absolute;
  bottom: 135px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 40px;
}

.choose {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  text-align: center;
}

.slider-main {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 120px;
}
.slider-main .swiper-container {
  width: calc(100% - 435px);
}
.slider-main__item {
  display: flex;
  justify-content: space-between;
  height: 360px;
  width: 80%;
}
.slider-main__content {
  width: 100%;
  height: 100%;
  background: radial-gradient(42.48% 63.78% at 50% 50%, #810384 33.33%, #170067 100%);
  padding: 52px 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  text-align: left;
}
.slider-main__title {
  font-size: 64px;
  line-height: 1;
  font-weight: 800;
  text-transform: uppercase;
}
.slider-main__title span {
  color: #52503B;
}
.slider-main__description p:last-of-type {
  margin-bottom: 0;
}

.socials {
  display: flex;
  align-items: center;
  width: 100%;
}
.socials__item {
  font-size: 32px;
}
.socials__item:not(:last-child) {
  margin-right: 20px;
}
.socials__link {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
}
.socials__link:hover {
  opacity: 0.4;
}

.feedback-slider__wrapper {
  position: relative;
  width: 100%;
  max-width: 1920px;
  z-index: 1;
  padding: 0 100px;
  margin: 70px auto 20px auto;
}
.feedback-slider__wrapper .swiper, .feedback-slider__wrapper .swiper-container {
  overflow: unset;
}
.feedback-slider__item {
  padding: 32px 42px;
  background: #FFFFFF;
  color: #333333;
  border-radius: 4px;
  text-align: left;
}
.feedback-slider__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 22px;
}
.feedback-slider-prev, .feedback-slider-next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #7D7C7C;
}
.feedback-slider-prev:hover, .feedback-slider-next:hover {
  cursor: pointer;
}
.feedback__rating {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 24px;
  color: #52503B;
}
.feedback__rating i {
  margin-right: 2px;
}
.feedback__title {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
}
.feedback__content {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  min-height: 100px;
}
.feedback__name {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 12px;
  border-left: 3px solid #70E8A0;
}

.form--feedback .form__section--submit {
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-gap: 16px;
}

.search {
  position: relative;
  width: 290px;
  flex-shrink: 0;
}
.search__button {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  color: #A7BAC9;
}
.search input {
  padding-left: 48px;
}

.modal {
  position: relative;
  background: #FFFFFF;
  border-radius: 24px;
  overflow: hidden;
  max-width: 680px;
  text-align: center;
}
.modal .button--close {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #EFF3F6;
}
.modal .button--close:hover {
  color: #009FF5;
}
.modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1043;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px 16px;
  background: rgba(55, 73, 87, 0.9);
}
.modal__wrapper .form__description {
  display: flex;
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  color: #009FF5;
  max-width: 248px;
  margin: auto;
}
.modal__wrapper .form__description i {
  font-size: 24px;
  margin-right: 8px;
}
.modal__wrapper .form__description p {
  margin-bottom: 0;
}
.modal__title {
  margin-bottom: 32px;
  text-align: center;
}
.modal__title h3 {
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 0;
}
.modal__content {
  padding: 60px;
}
.modal__content .form__section {
  text-align: center;
}
.modal__content .form__section .button {
  max-width: 100%;
}
.modal--quote .modal__content {
  padding: 24px 40px;
  text-align: center;
}
.modal--quote .modal__description {
  max-width: 400px;
  margin: 0 auto 40px auto;
  font-size: 18px;
  line-height: 24px;
}
.modal--quote .modal__description p {
  margin-bottom: 0;
}
.modal--quote .checkbox {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 40px auto 0 auto;
}

.rating-group {
  display: inline-flex;
}

.rating__icon {
  pointer-events: none;
}

.rating__input {
  position: absolute !important;
  left: -9999px !important;
}

.rating__label {
  cursor: pointer;
  padding: 0 6px;
  font-size: 44px;
}
.rating__label i {
  background: linear-gradient(71.92deg, #009FF5 0%, #59CA56 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.rating__icon--none {
  color: #eee;
}

.rating__input--none:checked + .rating__label .rating__icon--none {
  color: red;
}

.rating__input:checked ~ .rating__label i {
  background: #EAEFF3;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.rating-group:hover .rating__label i {
  background: linear-gradient(71.92deg, #009FF5 0%, #59CA56 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.rating__input:hover ~ .rating__label i {
  background: #EAEFF3;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.rating-group:hover .rating__input--none:not(:hover) + .rating__label .rating__icon--none {
  color: #eee;
}

.rating__input--none:hover + .rating__label .rating__icon--none {
  color: red;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
  margin-top: 40px;
  padding: 0 160px;
}
.services-list__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.services-list i {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  font-size: 48px;
  margin-bottom: 20px;
}
.services-list__name {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #7D7C7C;
}
.services-exception {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: #52503B;
  padding: 0 160px;
  margin-top: 40px;
  font-size: 14px;
}
.services-exception span {
  display: block;
}
.services-exception span:not(:last-child) {
  margin-bottom: 12px;
}

.service-list__name {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #7D7C7C;
  margin-bottom: 0;
}

.faq {
  width: 100%;
  text-align: left;
  margin-top: 60px;
}
.faq--open .faq__answer {
  visibility: visible;
  opacity: 1;
  height: auto;
  padding: 0 16px 16px 36px;
}
.faq--open .faq__question i {
  transform: rotate(0deg);
}
.faq__item {
  padding: 0 128px;
}
.faq__item:nth-child(odd) {
  background: #F5F5F5;
}
.faq__question {
  display: flex;
  padding: 16px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #7D7C7C;
  cursor: pointer;
}
.faq__question i {
  font-size: 10px;
  color: #7D7C7C;
  margin-right: 20px;
  transition: transform 0.4s ease-out;
  transform: rotate(-90deg);
}
.faq__question p {
  margin-bottom: 0;
}
.faq__answer {
  opacity: 0;
  visibility: hidden;
  height: 0;
  font-size: 16px;
  line-height: 20px;
  color: #7D7C7C;
  transition: opacity 1s ease-out;
}
.faq__answer p {
  margin-bottom: 0;
}

.swiper-half__title {
  font-weight: 500;
  font-size: 32px;
  line-height: 59px;
  margin-bottom: 0;
}
.swiper-half__description {
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  text-align: left;
}
.swiper-half__description p {
  line-height: 1.2;
}
.swiper-half__buttons {
  display: flex;
  align-items: center;
  width: 100%;
}
.swiper-half__buttons .button {
  margin-right: 42px;
}
.swiper-half__navigation {
  position: absolute;
  top: 50%;
  z-index: 11;
  left: 50%;
  transform: translate(-50%, -50%);
}
.swiper-half-prev, .swiper-half-next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  font-size: 12px;
  margin: 10px 0;
}
.swiper-half-prev.swiper-button-disabled, .swiper-half-next.swiper-button-disabled {
  transition: opacity 0.2s;
  opacity: 0;
}
.swiper-half-prev:hover, .swiper-half-next:hover {
  cursor: pointer;
}
.swiper-half.swiper-container {
  position: relative;
  overflow: hidden;
  height: 500px;
}
.swiper-half .swiper-slide {
  display: flex;
  overflow: hidden;
  width: 100%;
}
.swiper-half .swiper-slide.swiper-slide-active .swiper-half-l {
  opacity: 1;
  transform: translateY(0);
}
.swiper-half .swiper-slide.swiper-slide-active .swiper-half-r {
  opacity: 1;
  transform: translateY(0);
}
.swiper-half .swiper-slide .swiper-half-l {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  opacity: 0;
  position: relative;
  transform: translateY(-100%);
  transition: 1s ease;
  width: 50%;
  color: #FFFFFF;
  padding: 60px;
}
.swiper-half .swiper-slide .swiper-half-l img {
  width: 200px;
}
.swiper-half .swiper-slide .swiper-half-r {
  height: 100%;
  opacity: 0;
  position: relative;
  transition: 1s ease;
  transform: translateY(100%);
  width: 50%;
}
.swiper-half .swiper-slide .swiper-half-r i {
  position: absolute;
  top: 32px;
  left: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 116px;
  height: 116px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  font-size: 64px;
  color: #FFFFFF;
}
.swiper-half .swiper-slide .swiper-half-r img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.swiper-pagination.long {
  position: unset;
  justify-content: center;
  display: flex;
}
.swiper-pagination.long .swiper-pagination-bullet {
  height: 10px;
  width: 100px;
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  margin: 0 10px !important;
}
.swiper-pagination.long .swiper-pagination-bullet-active {
  background-color: #FFFFFF;
}

.swiper-pagination__wrapper {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.slider-features .swiper-slide .swiper-half-l {
  background-image: url(../img/backgrounds/glass.svg);
  background-repeat: no-repeat;
  background-position: bottom 40px right;
  background-size: 50%;
}
.slider-features .swiper-slide.ljmonade {
  background-color: #FBC028;
}
.slider-features .swiper-slide.eco {
  background-color: rgba(39, 145, 81, 0.59);
}

.slider-activity {
  margin-top: 30px;
}
.slider-activity .swiper-slide .swiper-half__title {
  margin-bottom: 0;
}
.slider-activity .swiper-slide .swiper-half__description {
  margin: 0;
}
.slider-activity .swiper-slide .swiper-half-l {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.slider-activity .swiper-slide.surfboard {
  background-color: rgba(70, 150, 155, 0.59);
}
.slider-activity .swiper-slide.surfboard .button--t2:hover {
  color: rgba(70, 150, 155, 0.59);
}
.slider-activity .swiper-slide.surfboard .swiper-half-l {
  background-image: url(../img/backgrounds/waves.svg);
  background-repeat: no-repeat;
  background-position: top 200px right;
}
.slider-activity .swiper-slide.yoga {
  background-color: #FBC028;
}
.slider-activity .swiper-slide.yoga .button--t2:hover {
  color: #FFC960;
}
.slider-activity .swiper-slide.hiking {
  background-color: rgba(103, 146, 49, 0.64);
}
.slider-activity .swiper-slide.hiking .button--t2:hover {
  color: rgba(103, 146, 49, 0.64);
}
.slider-activity .swiper-slide.sup {
  background-color: rgba(103, 146, 49, 0.64);
}
.slider-activity .swiper-slide.sup .button--t2:hover {
  color: rgba(103, 146, 49, 0.64);
}
.slider-activity .swiper-slide.dinner {
  background-color: rgba(215, 64, 44, 0.64);
}
.slider-activity .swiper-slide.dinner .button--t2:hover {
  color: rgba(215, 64, 44, 0.64);
}

.activity-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  background: #F5F5F5;
}
.activity-menu__item {
  font-size: 20px;
  line-height: 24px;
  margin: 0 25px;
}
.activity-menu__item:hover, .activity-menu__item.active {
  font-weight: 600;
}

.slider-activities-item__wrapper {
  max-height: 500px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
}
.slider-activities-item__wrapper .swiper {
  height: 100%;
  width: 100%;
}
.slider-activities-item__wrapper .swiper-slide {
  max-height: 500px;
}
.slider-activities-item__wrapper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider-activities-item-thumbs__wrapper {
  width: 100%;
  padding: 15px;
  background: #F5F5F5;
  border-radius: 10px;
  margin-bottom: 30px;
}
.slider-activities-item-thumbs__wrapper .swiper {
  height: 100%;
}
.slider-activities-item-thumbs__wrapper .swiper .swiper-slide {
  max-height: 180px;
  border-radius: 10px;
  overflow: hidden;
}
.slider-activities-item-thumbs__wrapper .swiper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-room__wrapper {
  position: relative;
  max-height: 500px;
  border-radius: 10px;
  overflow: hidden;
}
.slider-room__wrapper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider-room-thumbs__wrapper {
  width: 100%;
  padding: 15px;
  background: #F5F5F5;
  border-radius: 10px;
}
.slider-room-thumbs__wrapper .swiper {
  height: 100%;
}
.slider-room-thumbs__wrapper .swiper .swiper-slide {
  border-radius: 10px;
  max-height: 180px;
  overflow: hidden;
}
.slider-room__buttons {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
}
.slider-room-prev, .slider-room-next {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  background: rgba(255, 255, 255, 0.3);
}
.slider-room-prev:hover, .slider-room-next:hover {
  cursor: pointer;
}

.rooms-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 500px;
  align-content: center;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  overflow: hidden;
}
.rooms-header.eco {
  background-image: url(../img/backgrounds/lime-green.svg);
}
.rooms-header.regular {
  background-image: url(../img/backgrounds/lime-yellow.svg);
}
.rooms-header__img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-position: center;
}
.rooms-header__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}
.rooms-header__content h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  color: #444444;
}
.rooms-header__content p {
  font-size: 20px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  color: #444444;
  margin-bottom: 0;
}
.rooms-header__description {
  max-width: 580px;
}
.rooms-logo {
  position: absolute;
  height: 120px;
}
.rooms-header-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rooms-type {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rooms-type i {
  margin-right: 12px;
  font-size: 28px;
}
.rooms-type.eco i {
  color: #70E8A0;
}
.rooms-type.regular i {
  color: #FFDE57;
}
.rooms__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}
.rooms-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.rooms-item__preview {
  position: relative;
  border-radius: 10px;
  margin-bottom: 30px;
  aspect-ratio: 3/2;
  overflow: hidden;
  background-size: contain;
  background: #F5F5F5 url("../img/backgrounds/lime.svg") no-repeat center;
  background-size: 200px;
}
.rooms-item__preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rooms-item__price {
  position: absolute;
  left: 24px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 50px;
  background: rgba(112, 232, 160, 0.8);
  border-radius: 10px 10px 0 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
}
.rooms-item__availability {
  position: absolute;
  top: 7px;
  right: 7px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  font-size: 8px;
  line-height: 10px;
  color: #444444;
}
.rooms-item__availability a {
  display: flex;
  flex-direction: column;
  padding: 6px;
}
.rooms-item__availability i {
  font-size: 24px;
  line-height: 1;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.5);
}
.rooms-item__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #484848;
  margin-bottom: 5px;
  text-align: left;
}
.rooms-item__description {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #9A9A9A;
  margin-bottom: 16px;
  text-align: left;
  min-height: 68px;
}
.rooms-item__description p {
  margin-bottom: 0;
}
.rooms-item__highlights {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
  grid-gap: 16px;
  width: 100%;
  font-size: 32px;
}
.rooms-item__buttons {
  margin-top: 40px;
}
.rooms-item__buttons .button {
  width: 100%;
  min-width: unset;
  height: 60px;
}

.room-wrapper {
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-gap: 30px;
}
.room__price {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  padding: 12px;
  background: rgba(112, 232, 160, 0.8);
  border-radius: 10px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
}
.room__price .price__volume {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin-right: 2px;
}
.room-amenities {
  padding: 30px;
  background: #F5F5F5;
  border-radius: 10px;
  height: 100%;
  color: #484848;
}
.room-amenities__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 30px;
}
.room-amenities__list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.room-amenities__list li {
  display: flex;
  align-items: center;
}
.room-amenities__list li i {
  width: 30px;
  height: 30px;
  font-size: 30px;
  margin-right: 20px;
}
.room-amenities__list li span {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.room-rules {
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0 12px 36px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
}
.room-rules__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
}
.room-rules__time {
  display: flex;
  margin-bottom: 32px;
}
.room-rules__time .check {
  display: flex;
  flex-direction: column;
}
.room-rules__time .check:first-child {
  margin-right: 60px;
}
.room-rules__time .check__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6D6D6D;
  margin-bottom: 8px;
}
.room-rules__time .check__time {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #70E8A0;
}
.room-rules__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  font-size: 14px;
  line-height: 17px;
  color: #6D6D6D;
}
.room-rules__list li {
  display: flex;
  align-items: center;
}
.room-rules__list li:before {
  content: "";
  display: block;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background: #70E8A0;
  margin-right: 12px;
}
.room-info {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.room-booking {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  height: 100%;
  max-height: 500px;
}
.room-booking__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 30px;
}
.room-booking .form__section {
  width: 100%;
}
.room-safety {
  margin-top: auto;
  padding: 30px 0;
}
.room-safety__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #484848;
  margin-bottom: 30px;
}
.room-safety__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px 20px;
}
.room-safety__item {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #484848;
}
.room-safety__item i {
  font-size: 32px;
  margin-right: 16px;
}

.activities-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: auto;
  height: 500px;
  overflow: hidden;
}
.activities-header.hiking .activities-header__img:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 110%;
  height: 100%;
  background-image: url(../img/backgrounds/hiking.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
}
.activities-header__img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 500px;
  height: 100%;
  background-position: center;
}
.activities-header__img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
}
.activities-header__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}
.activities-header__content h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  color: #444444;
}
.activities-header__content p {
  font-size: 18px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  color: #444444;
  margin-bottom: 10px;
}
.activities-header__description {
  max-width: 580px;
}
.activities-item {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 20px;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.activities-item:not(:last-child) {
  margin-bottom: 60px;
}
.activities-item .button-link {
  color: #70E8A0;
  justify-content: flex-end;
}
.activities-item__description {
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: left;
}
.activities-item__description p:last-child {
  margin-bottom: 0;
}
.activities-item__short-description {
  height: 100%;
}
.activities-item__params {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.activities-item__footer {
  margin-top: auto;
  padding-top: 20px;
  padding-right: 40px;
  border-top: 1px solid #7D7C7C;
}
.activities-item__photos {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
}
.activities__preview {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 30px;
  height: 100%;
}
.activities__preview img {
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  object-fit: cover;
}
.activities__photo {
  border-radius: 10px;
  overflow: hidden;
}
.activities__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.activities-level {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 125px;
  border-radius: 10px;
  color: #FFFFFF;
  font-weight: 600;
}
.activities-level.easy {
  background: rgba(112, 232, 160, 0.8);
}
.activities-param {
  display: flex;
  align-items: center;
  margin-left: 40px;
  font-size: 14px;
  color: #7D7C7C;
}
.activities-param i {
  font-size: 24px;
  margin-right: 12px;
}
.activities-item__wrapper {
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-gap: 60px;
  padding-top: 60px;
  padding-bottom: 100px;
}
.activities-item__characteristics {
  padding: 40px;
  background: #F5F5F5;
  border-radius: 10px;
}
.activities-item__characteristics .activities-item__params {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.activities-item__characteristics .activities-param {
  height: 50px;
  padding: 0 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  margin-left: 20px;
}
.activities-item__text h1 {
  color: #112211;
  font-size: 32px;
}
.activities-item__extra-info {
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0 12.4444px 35.5556px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
}
.activities-item__extra-info h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #4A4A4A;
  margin-bottom: 40px;
}
.activities-item__extra-info ol {
  color: #000000;
  padding-left: 40px;
}
.activities-item__extra-info ol li {
  display: list-item;
  margin-bottom: 12px;
}
.activities-category__list {
  display: flex;
  flex-direction: column;
}
.activities-category__img i {
  position: absolute;
  top: 32px;
  left: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 116px;
  height: 116px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  font-size: 64px;
  color: #FFFFFF;
}

.long-stay-item__wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 60px;
  padding-top: 60px;
  padding-bottom: 100px;
}
.long-stay-item__wrapper .activities-item__info {
  border-radius: 10px;
  overflow: hidden;
}

.long-stay-amenities__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.long-stay-amenities__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  text-align: center;
}
.long-stay-amenities__list i {
  font-size: 32px;
  margin-bottom: 8px;
}
.long-stay-amenities__list span {
  font-size: 14px;
  font-weight: 600;
}
.long-stay-amenities__list a:hover {
  color: #7D7C7C;
}

.long-stay__packages {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.long-stay__price {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0 12.4444px 35.5556px rgba(0, 0, 0, 0.11);
  border-radius: 15px;
  text-align: center;
  color: #484848;
}
.long-stay__price li {
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
}
.long-stay__price h4 {
  color: #484848;
  margin-bottom: 0;
}
.long-stay__price b {
  color: #484848;
}

.long-stay-amenities {
  padding: 30px;
  background: #F5F5F5;
  border-radius: 10px;
  color: #484848;
  text-align: center;
}

.promo__content h4 {
  font-weight: 600;
  font-size: 48px;
  line-height: 59px;
  margin-bottom: 60px;
  color: #FFFFFF;
}

.breadcrumbs {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #7D7C7C;
  margin-bottom: 30px;
}
.breadcrumbs a:hover {
  color: #434343;
}
.breadcrumbs li:not(:last-child):after {
  content: "/";
}
.breadcrumbs li:last-child {
  color: #434343;
}

.booking-calendar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  background: #FDFDFD;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 40px;
}
.booking-calendar .air-datepicker.-inline- {
  border: none;
  background: transparent;
  width: 100%;
}
.booking-calendar .air-datepicker-nav--title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  text-transform: unset;
}
.booking-calendar .air-datepicker-nav--title i {
  color: #000000;
}
.booking-calendar .air-datepicker-body--day-name {
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  font-weight: 500;
}
.booking-calendar .air-datepicker-nav--action path {
  stroke: #70E8A0;
}
.booking-calendar .air-datepicker-nav {
  border-bottom: none;
}
.booking-calendar__wrapper {
  display: flex;
  flex-direction: column;
}
.booking-calendar__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #7D7C7C;
  padding-bottom: 8px;
  margin-bottom: 8px;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #D9D9D9;
}
.booking-legend {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #9A9A9A;
  padding: 0 16px;
}
.booking-legend__item {
  display: flex;
  align-items: center;
}
.booking-legend__item:not(:last-child) {
  margin-bottom: 12px;
}
.booking-legend__item .booked, .booking-legend__item .avaliable, .booking-legend__item .yours {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 12px;
  color: #000000;
}
.booking-legend__item .booked {
  background: #D9D9D9;
}
.booking-legend__item .yours {
  background: #FFDE57;
}
.booking-steps {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
}
.booking-steps:before {
  content: "";
  position: absolute;
  top: 50%;
  z-index: -1;
  transform: translateY(-50%);
  display: block;
  height: 1px;
  width: 300px;
  background: #E5E5E5;
}
.booking-steps .step {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: #E5E5E5;
  font-size: 24px;
  color: #898989;
}
.booking-steps .step:not(:last-child) {
  margin-right: 80px;
}
.booking-steps .step.current {
  font-weight: 500;
  background: rgba(112, 232, 160, 0.5);
}
.booking-steps .step.current:before {
  content: "";
  position: absolute;
  z-index: -1;
  display: block;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
}
.booking__wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 240px;
}
.booking__wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 1px;
  height: 50%;
  background: #E5E5E5;
}
.booking__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  grid-gap: 40px;
}
.booking__img {
  width: 100%;
  max-height: 450px;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 30px;
}
.booking__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.booking__total {
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.booking__type {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: rgba(17, 34, 17, 0.7);
  margin-bottom: 15px;
}
.booking__type img {
  width: 26px;
  height: 26px;
  margin-right: 4px;
}
.booking__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #112211;
  margin-bottom: 15px;
}
.booking__details {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #112211;
}
.booking__details li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
.booking__details li:last-child {
  border-top: 1px solid #E5E5E5;
  padding-top: 30px;
  margin-top: 15px;
}
.booking__value {
  font-weight: 600;
}
.booking__dates {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 8px;
  background-color: rgba(112, 232, 160, 0.3);
  border-radius: 10px;
  margin-bottom: 15px;
  background-image: url("../img/elements/range.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.booking__checkin .booking__date, .booking__checkout .booking__date {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
}
.booking__checkin span, .booking__checkout span {
  font-weight: 500;
  font-size: 14px;
}
.booking__checkin .booking__date {
  color: #52503B;
}
.booking__checkout {
  text-align: right;
}
.booking__form .booking__buttons .button {
  width: 100%;
}
.booking-payment {
  padding: 30px;
  background-color: #FFFFFF;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-image: url("../img/backgrounds/glass-black.svg");
  background-repeat: no-repeat;
  background-position: bottom 120px right;
  background-size: 200px;
  margin-bottom: 60px;
}
.booking-payment__description a {
  color: #0070E0;
  font-weight: 500;
}
.booking-payment__method {
  display: flex;
  align-items: center;
  margin: 50px 0;
}
.booking-payment .button--t3 {
  color: #FFFFFF;
  letter-spacing: unset;
}
.booking-payment__methods {
  background-color: #FFFFFF;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.booking-payment__methods .form__section {
  width: 100%;
  padding: 16px;
}
.booking-payment__methods .booking__buttons {
  padding: 30px;
  margin-top: 0;
  border-top: 1px solid #E5E5E5;
}
.booking-payment__methods .button--t3 {
  color: #FFFFFF;
  letter-spacing: unset;
}
.booking-notification__success {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 450px;
  background-color: #FFFFFF;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 30px;
  background-image: url("../img/backgrounds/glass-black.svg");
  background-repeat: no-repeat;
  background-position: bottom 30px center;
  background-size: 200px;
  padding: 90px 30px;
}
.booking-notification__success i {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #70E8A0;
  font-size: 32px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #FFFFFF;
  margin-bottom: 50px;
}
.booking-notification__success h4 {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 0;
  text-align: center;
}
.booking-notification__description {
  color: #52503B;
}

.contacts__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  padding: 40px 0;
}
.contacts__address {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  grid-gap: 40px;
}
.contacts__address li {
  margin-bottom: 5px;
  user-select: auto;
}
.contacts__address li a {
  text-decoration: underline;
}
.contacts__address li a:hover {
  text-decoration: none;
}
.contacts__address address {
  font-style: normal;
}

.header-menu {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 24px;
}
.header-menu__list {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
}
.header-menu__item {
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.header-menu__item.eco i {
  color: #70E8A0;
}
.header-menu__item.regular i {
  color: #FFDE57;
}
.header-menu__item i {
  font-size: 8px;
  margin-left: 12px;
}
.header-menu__item:not(:last-child) {
  margin-right: 32px;
}
.header-menu__link {
  position: relative;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: color 0.2s ease-out;
}
.header-menu__link:hover {
  cursor: pointer;
}
.header-menu__link.opened i {
  transform: rotate(180deg);
}

.header-submenu {
  position: absolute;
  top: calc(100% + 12px);
  left: 0;
  z-index: 9;
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  display: none;
}
.header-submenu .header-menu__item {
  height: 80px;
  padding: 0 25px;
}
.header-submenu .header-menu__item:not(:last-child) {
  margin-right: 0;
}
.header-submenu .header-menu__item.active {
  color: inherit;
  background: #EBFFEB;
}
.header-submenu .header-menu__item:hover {
  color: inherit;
  background: rgba(235, 255, 235, 0.5);
}
.header-submenu .header-menu__link {
  display: flex;
  align-items: center;
}
.header-submenu .header-menu__link i {
  font-size: 28px;
  margin-left: 0;
  margin-right: 12px;
}

.footer-menu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.footer-menu__item {
  margin-bottom: 10px;
}
.footer-menu__item-link {
  transition: opacity 0.2s;
}
.footer-menu__item-link:hover {
  opacity: 0.4;
}

.burger-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
}
.burger-menu__item {
  padding: 10px;
  width: 100%;
  text-align: center;
}
.burger-menu__item:not(:last-child) {
  border-bottom: 1px solid #EAEFF3;
}
.burger-menu__item.eco i {
  color: #70E8A0;
}
.burger-menu__item.regular i {
  color: #FFDE57;
}
.burger-menu__item.coliving i {
  color: rgba(215, 64, 44, 0.64);
}
.burger-menu__title {
  font-weight: 600;
  margin-bottom: 8px;
  color: #444;
  text-align: center;
}
.burger-menu__link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.burger-menu__link.bold {
  color: #444;
  font-weight: 600;
}
.burger-menu__link i {
  margin-right: 10px;
}
.burger-menu__footer {
  margin-top: auto;
}
.burger-menu__footer .burger-menu__title {
  margin-bottom: 10px;
  font-size: 12px;
  text-transform: uppercase;
}
.burger-menu__footer .socials {
  margin-bottom: 42px;
  justify-content: center;
}
.burger-menu__footer .socials .socials__item {
  font-size: 24px;
}
.burger-menu__footer .logo a img {
  height: 32px;
}
.burger-menu__wrapper {
  position: fixed;
  top: 0;
  visibility: hidden;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 480px;
  background: #F7FAFB;
  z-index: 11;
  transition: all 0.3s;
  padding: 24px 16px;
  overflow: auto;
}
.burger-menu__wrapper.opened {
  visibility: visible;
  opacity: 1;
}
.burger-menu__opener {
  font-size: 32px;
  cursor: pointer;
}
.burger-menu__closer {
  position: absolute;
  right: 32px;
  top: 32px;
  font-size: 32px;
  cursor: pointer;
  z-index: 12;
}

.burger-submenu {
  margin-bottom: 30px;
}

.menu-mobile {
  position: fixed;
  bottom: 0;
  z-index: 10;
  display: none;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  color: #A7BAC9;
  padding: 8px;
  background: #F7FAFB;
}
.menu-mobile__item {
  text-align: center;
}
.menu-mobile__item span {
  font-size: 8px;
  line-height: 10px;
}
.menu-mobile__item i {
  font-size: 28px;
}
.menu-mobile__item.active {
  color: #009FF5;
}
.menu-mobile__link {
  display: flex;
  flex-direction: column;
}
.menu-mobile__link.active {
  color: #009FF5;
}

.profile-mobile__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  padding: 64px 16px 54px 16px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0 4px 40px rgba(55, 73, 87, 0.12);
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  transition: opacity 0.2s;
}
.profile-mobile__wrapper.opened {
  opacity: 1;
  visibility: visible;
}
.profile-mobile__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.form--availability {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  max-width: 1100px;
  margin: 60px auto;
}
.form--availability .button--t1 {
  height: 80px;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: normal;
}
.form--availability .form__input:not(:last-child) {
  margin-bottom: 0;
}
.form--availability .form__input input {
  height: 80px;
  padding: 0 25px;
}

.form--booking {
  padding: 30px;
  background: #FDFDFD;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.form--booking .form__input:not(:last-child) {
  margin-bottom: 30px;
}
.form--booking input {
  height: 50px;
  background: #F5F6F8;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #7D7C7C;
}
.form--booking .form__input--date {
  max-width: 100%;
}
.form--booking .form__input--date i {
  top: auto;
  bottom: 0;
}
.form--booking .form__section--submit {
  margin-top: 60px;
}
.form--booking .room__total {
  font-weight: 400;
  font-size: 16px;
  color: #B0B0B0;
  margin-bottom: 10px;
}
.form--booking .room__total span {
  font-weight: 600;
  color: #7D7C7C;
}