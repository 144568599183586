
.slider-features {


    .swiper-slide {
        .swiper-half-l {
            background-image: url(../img/backgrounds/glass.svg);
            background-repeat: no-repeat;
            background-position: bottom 40px right;
            background-size: 50%;
        }
        &.ljmonade {
            background-color: #FBC028;
        }
        &.eco {
            background-color: rgba(39, 145, 81, 0.59);
        }
    }
}
