/***
*
* iconfont.scss генерируется динамечески со списком классов для иконочного шрифта
* _iconfont-template.styl - шаблон для генерации файла iconfont.styl
*
***/

/* Генерация рандомного числа, для обнуления кеша при деплое на песок и прод */

//$rand: math.random(10000);

//@font-face {
//	font-family: "iconfont";
//	src: url('../fonts/iconfont/iconfont.eot?v='$rand);
//	src: url('../fonts/iconfont/iconfont.eot?v='$rand'#iefix') format('eot'),
//		url('../fonts/iconfont/iconfont.woff2?v='$rand) format('woff2'),
//		url('../fonts/iconfont/iconfont.woff?v='$rand) format('woff'),
//		url('../fonts/iconfont/iconfont.ttf?v='$rand) format('truetype'),
//		url('../fonts/iconfont/iconfont.svg?v='$rand'#iconfont') format('svg');
//}

@font-face {
	font-family: "iconfont";
	src: url('../fonts/iconfont/iconfont.eot');
	src: url('../fonts/iconfont/iconfont.eot?#iefix') format('eot'),
		url('../fonts/iconfont/iconfont.woff2') format('woff2'),
		url('../fonts/iconfont/iconfont.woff') format('woff'),
		url('../fonts/iconfont/iconfont.ttf') format('truetype'),
		url('../fonts/iconfont/iconfont.svg?#iconfont') format('svg');
}


[class^="icon-"], [class*=" icon-"] {
	display: flex;
	justify-content: center;
	align-items: center;
	&::before {
		font-family: "iconfont";
		speak: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		text-decoration: none;
		text-transform: none;
	}
}


.icon-air_conditioning::before {
	content: "\E001";
}
.icon-area::before {
	content: "\E002";
}
.icon-arrow-left::before {
	content: "\E003";
}
.icon-arrow-right::before {
	content: "\E004";
}
.icon-arrow::before {
	content: "\E005";
}
.icon-balcony::before {
	content: "\E006";
}
.icon-bathroom::before {
	content: "\E007";
}
.icon-bed::before {
	content: "\E008";
}
.icon-bed_single::before {
	content: "\E009";
}
.icon-bedroom_ensuite::before {
	content: "\E00A";
}
.icon-bill::before {
	content: "\E00B";
}
.icon-breakfast::before {
	content: "\E00C";
}
.icon-calendar::before {
	content: "\E00D";
}
.icon-check::before {
	content: "\E00E";
}
.icon-cleaning::before {
	content: "\E00F";
}
.icon-cleaning_included::before {
	content: "\E010";
}
.icon-close::before {
	content: "\E011";
}
.icon-coffe::before {
	content: "\E012";
}
.icon-conditioner::before {
	content: "\E013";
}
.icon-dinner::before {
	content: "\E014";
}
.icon-group::before {
	content: "\E015";
}
.icon-hair_dryer::before {
	content: "\E016";
}
.icon-hangers::before {
	content: "\E017";
}
.icon-heating::before {
	content: "\E018";
}
.icon-hiking::before {
	content: "\E019";
}
.icon-kitchen-room::before {
	content: "\E01A";
}
.icon-kitchen::before {
	content: "\E01B";
}
.icon-laundry::before {
	content: "\E01C";
}
.icon-lime::before {
	content: "\E01D";
}
.icon-locker::before {
	content: "\E01E";
}
.icon-lockers::before {
	content: "\E01F";
}
.icon-menu::before {
	content: "\E020";
}
.icon-phone::before {
	content: "\E021";
}
.icon-restaurant::before {
	content: "\E022";
}
.icon-route::before {
	content: "\E023";
}
.icon-routes::before {
	content: "\E024";
}
.icon-security::before {
	content: "\E025";
}
.icon-shared_kitchen::before {
	content: "\E026";
}
.icon-star-empty::before {
	content: "\E027";
}
.icon-star::before {
	content: "\E028";
}
.icon-sup::before {
	content: "\E029";
}
.icon-surfboard::before {
	content: "\E02A";
}
.icon-temperature::before {
	content: "\E02B";
}
.icon-timer::before {
	content: "\E02C";
}
.icon-towels::before {
	content: "\E02D";
}
.icon-transfer::before {
	content: "\E02E";
}
.icon-tv::before {
	content: "\E02F";
}
.icon-up::before {
	content: "\E030";
}
.icon-washing-machine::before {
	content: "\E031";
}
.icon-wifi::before {
	content: "\E032";
}
.icon-yoga::before {
	content: "\E033";
}
.icon-airbnb::before {
	content: "\E034";
}
.icon-facebook::before {
	content: "\E035";
}
.icon-instagram::before {
	content: "\E036";
}
.icon-telegram::before {
	content: "\E037";
}
.icon-tiktok::before {
	content: "\E038";
}
.icon-twitter::before {
	content: "\E039";
}
.icon-youtube::before {
	content: "\E03A";
}