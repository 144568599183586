
// -----------------------------------------------
// Pages styles
// -----------------------------------------------



.page {
    //border-top: 1px solid #9A9A9A;
    padding-top: 64px;
    padding-bottom: 50px;
    & .faq {
        margin-top: 0;
    }
    &-main {
        padding: 0;
        border: none;
    }
    &-rooms {
        padding: 0;
        border: none;
    }
    &-room {
        border-top: 1px solid #9A9A9A;
        padding-top: 56px;
        padding-bottom: 250px;
        background-image: url(../img/backgrounds/castle.svg);
        background-repeat: no-repeat;
        background-position: bottom right 140px;
    }
    &-activities {
        border: none;
        padding-top: 0;
        padding-bottom: 0;
    }
    &-form {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        min-height: 100%;
        padding-bottom: 60px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        & .logo {
            margin-top: 102px;
            margin-bottom: 40px;
            font-size: 64px;
            line-height: 42px;
            color: #FFFFFF;
        }
    }
    &-error {
        border: none;
        padding: 0 0 60px 0;
        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 1062px;
            margin: auto;
            text-align: center;
            background-color: #F7F7F7;
            background-repeat: no-repeat;
            background-position: bottom center;
            background-size: cover;
            padding: 80px 40px;
            border-radius: 20px;
            & img {
                width: 200px;
                margin-bottom: 30px;
            }

        }
        &__title {
            font-size: 36px;
            line-height: 44px;
            font-weight: 600;
        }
        &__description {
            margin-bottom: 32px;
            font-size: 18px;
        }
    }
    &__title {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        margin-bottom: 20px;
        color: #52503B;
        text-align: center;
    }
    &__subtitle {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #52503B;
        text-align: center;
    }
    & .notification {
        text-align: center;
        margin: auto;
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: 700;
    }
}