/*
* Лоадер
* Используется при загрузке страницы
*/

.loader {
    background-color: rgba(70, 150, 155, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & img {
        width: 80px
    }
    .lds-ellipsis {
        display: block;
        position: relative;
        width: 80px;
        height: 80px;
        margin-top: -24px;
        margin-left: 10px;
    }
}

.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #FDC70D;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

.loader-box {
    width: 24px;
    height: 24px;
    display: flex;
    margin: 0 auto 18px auto;
}

.loader-container {
    animation: rotate 1s infinite;
    height: 18px;
    width: 18px;
}

.loader-container:before,
.loader-container:after {
    border-radius: 50%;
    content: "";
    display: block;
    height: 8px;
    width: 8px;
}
.loader-container:before {
    animation: ball1 1s infinite;
    background-color: #1DAAF9;
    box-shadow: 16px 0 0 #FFCE0A;
    margin-bottom: 4px;
}
.loader-container:after {
    animation: ball2 1s infinite;
    background-color: #36D680;
    box-shadow: 16px 0 0 #E374FF;
}

@keyframes rotate {
    0% { transform: rotate(0deg) scale(0.8) }
    50% { transform: rotate(360deg) scale(1.2) }
    100% { transform: rotate(720deg) scale(0.8) }
}

@keyframes ball1 {
    0% {
        box-shadow: 12px 0 0 #E374FF;
    }
    50% {
        box-shadow: 0 0 0 #E374FF;
        margin-bottom: 0;
        transform: translate(4px, 4px);
    }
    100% {
        box-shadow: 12px 0 0 #E374FF;
        margin-bottom: 4px;
    }
}

@keyframes ball2 {
    0% {
        box-shadow: 12px 0 0 #FFCE0A;
    }
    50% {
        box-shadow: 0 0 0 #FFCE0A;
        margin-top: -8px;
        transform: translate(4px, 4px);
    }
    100% {
        box-shadow: 12px 0 0 #FFCE0A;
        margin-top: 0;
    }
}
