// -----------------------------------------------------------------------------
// Global Variables
// -----------------------------------------------------------------------------
$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Roboto', sans-serif;
$font-iconfont: 'iconfont';
$font-size-default: 16px;
$font-size-smaller: 14px;
$font-size-bigger: 20px;

// ----- colors ----------------------------------------------------------------
$color-body: #FFFFFF;
$color-text: #7D7C7C;
$color-text-dark: #52503B;
$color-text-custom: #FFDC69;
$color-border: #FFDE57;
$color-disabled: #9FA4BC;

// ----- colors of statuses----------------------------------------------------------------
$color-success: #239024;
$color-error: #DF2D14;
$color-processing: #39AFFF;
$color-finished: #B30000;
$color-expired: #9FA4BC;
$color-cancel: #170022;

// ----- inputs colors ---------------------------------------------------------
$input-background: #FFFFFF;

// ----- transitions -----------------------------------------------------------
$t-opacity: opacity .2s;
$t-visibility: visibility .2s;
$t-filter: filter .2s ease-out;
$t-color: color .2s ease-out;
$t-bg-color: background-color .2s ease-out;
$t-box-shadow: box-shadow .2s ease-out;
$t-text-shadow: text-shadow .2s ease-out;
$t-border-color: border-color .2s ease-out;
$t-transform: transform .2s ease-out;


// ----- MIXINS -----------------------------------------------------------

/* arrow */
@mixin dropdownIconMixin {
    &:before, &:after {
        content: '';
        display: block;
        box-sizing: border-box;
        position: absolute;
        right: 22px;
        top: 50%;
        height: 1px;
        width: 9px;
        border-radius: 2px;
        background: #260303;
        backface-visibility: hidden;
        transition-property: transform;
        transition-duration: .2s;
    }
    &:before {
        transform: rotate(45deg);
        right: 28px;
    }
    &:after {
        transform: rotate(-45deg);
    }
}

@mixin dropdownIconOpenedMixin {
    &:before {
        transform: rotate(-45deg);
    }
    &:after {
        transform: rotate(45deg);
    }
}

/* text overflow ellipsis */
@mixin textOverflowMixin {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}