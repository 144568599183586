.socials {
    display: flex;
    align-items: center;
    width: 100%;
    &__item {
        font-size: 32px;
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $t-opacity;
        &:hover {
            opacity: .4;
        }
    }
}
