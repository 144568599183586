.slider-activities-item {
    &__wrapper {
        max-height: 500px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 30px;
        & .swiper {
            height: 100%;
            width: 100%;
        }
        & .swiper-slide {
            max-height: 500px;
            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &-thumbs__wrapper {
        width: 100%;
        padding: 15px;
        background: #F5F5F5;
        border-radius: 10px;
        margin-bottom: 30px;
        & .swiper {
            height: 100%;
            & .swiper-slide {
                max-height: 180px;
                border-radius: 10px;
                overflow: hidden;
                & img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}