.form--availability {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    max-width: 1100px;
    margin: 60px auto;
    & .button--t1 {
        height: 80px;
        font-weight: 300;
        font-size: 20px;
        letter-spacing: normal;
    }
    & .form__input {
        &:not(:last-child) {
            margin-bottom: 0;
        }
        & input {
            height: 80px;
            padding: 0 25px;
        }
    }
}