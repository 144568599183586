.slider-room {
    &__wrapper {
        position: relative;
        max-height: 500px;
        border-radius: 10px;
        overflow: hidden;
        & .swiper-slide {
            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    &-thumbs__wrapper {
        width: 100%;
        padding: 15px;
        background: #F5F5F5;
        border-radius: 10px;
        & .swiper {
            height: 100%;
            & .swiper-slide {
                border-radius: 10px;
                max-height: 180px;
                overflow: hidden;
            }
        }
    }
    &__buttons {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 20px;
    }
    &-prev, &-next {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
        background: rgba(255, 255, 255, .3);
        &:hover {
            cursor: pointer;
        }
    }
}