.activities {
    &-header {
        display: grid;
        grid-template-columns: 1fr 1fr;
        background-repeat: no-repeat;
        background-position: center right;
        background-size: auto;
        height: 500px;
        overflow: hidden;
        &.hiking {
            & .activities-header__img {
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 110%;
                    height: 100%;
                    background-image: url(../img/backgrounds/hiking.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: bottom;

                }
            }
        }
        &__img {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 500px;
            height: 100%;
            background-position: center;

            & img {
                width: 100%;
                object-fit: cover;
                object-position: center;
                height: 100%;
            }
        }
        &__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 20px;
            & h1 {
                font-weight: 600;
                font-size: 32px;
                line-height: 36px;
                color: #444444;
            }
            & p {
                font-size: 18px;
                line-height: 1.2;
                display: flex;
                align-items: center;
                color: #444444;
                margin-bottom: 10px;
            }
        }
        &__description {
            max-width: 580px;
        }
    }
    &-item {
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-gap: 20px;
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0 6px 10px 2px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        &:not(:last-child) {
            margin-bottom: 60px;
        }
        & .button-link {
            color: #70E8A0;
            justify-content: flex-end;
        }
        &__description {
            display: flex;
            flex-direction: column;
            gap: 30px;
            text-align: left;
            & p:last-child {
                margin-bottom: 0;
            }
        }
        &__short-description {
            height: 100%;
        }
        &__params  {
            display: flex;
            align-items: center;
            padding-bottom: 20px;


        }
        &__footer {
            margin-top: auto;
            padding-top: 20px;
            padding-right: 40px;
            border-top: 1px solid #7D7C7C;
        }
        &__photos  {
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-gap: 30px;

        }

    }
    &__preview {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-gap: 30px;
        height: 100%;
        & img {
            border-radius: 10px;
            overflow: hidden;
            height: 100%;
            object-fit: cover;
        }
    }
    &__photo {
        border-radius: 10px;
        overflow: hidden;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-level {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 125px;
        border-radius: 10px;
        color: #FFFFFF;
        font-weight: 600;
        &.easy {
            background: rgba(112, 232, 160, 0.8);
        }
    }
    &-param {
        display: flex;
        align-items: center;
        margin-left: 40px;
        font-size: 14px;
        color: #7D7C7C;
        & i {
            font-size: 24px;
            margin-right: 12px;
        }
    }
    &-item {
        &__wrapper {
            display: grid;
            grid-template-columns: 50% 1fr;
            grid-gap: 60px;
            padding-top: 60px;
            padding-bottom: 100px;

        }
        &__characteristics {
            padding: 40px;
            background: #F5F5F5;
            border-radius: 10px;
            & .activities-item__params {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;

            }
            & .activities-param {
                height: 50px;
                padding: 0 20px;
                background: rgba(255, 255, 255, 0.8);
                border-radius: 10px;
                margin-left: 20px;
            }
        }
        &__text {
            & h1 {
                color: #112211;
                font-size: 32px;
            }
        }
        &__extra-info {
            padding: 20px;
            background: #FFFFFF;
            box-shadow: 0 12.4444px 35.5556px rgba(0, 0, 0, 0.11);
            border-radius: 15px;
            & h3 {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                color: #4A4A4A;
                margin-bottom: 40px;
            }
            & ol {
                color: #000000;
                padding-left: 40px;
                & li {
                    display: list-item;
                    margin-bottom: 12px;
                }
            }
        }
    }
    &-category {
        &__list {
            display: flex;
            flex-direction: column;
        }
        &__img {
            & i {
                position: absolute;
                top: 32px;
                left: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 116px;
                height: 116px;
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.5);
                font-size: 64px;
                color: #FFFFFF;
            }
        }
    }
}
.long-stay-item__wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 60px;
    padding-top: 60px;
    padding-bottom: 100px;
    & .activities-item__info {
        border-radius: 10px;
        overflow: hidden;
    }
}
.long-stay-amenities__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
}
.long-stay-amenities__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    text-align: center;
    & i {
        font-size: 32px;
        margin-bottom: 8px;
    }
    & span {
        font-size: 14px;
        font-weight: 600;
    }
    & a {
        &:hover {
            color: #7D7C7C;
        }
    }
}
.long-stay__packages {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
}
.long-stay__price {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0 12.4444px 35.5556px rgba(0, 0, 0, 0.11);
    border-radius: 15px;
    text-align: center;
    color: #484848;
    & li {
        display: flex;
        justify-content: space-between;
        width: 100%;
        text-align: left;
    }
    & h4 {
        color: #484848;
        margin-bottom: 0;
    }
    & b {
        color: #484848;
    }
}
.long-stay-amenities {
    padding: 30px;
    background: #F5F5F5;
    border-radius: 10px;
    color: #484848;
    text-align: center;
}